import { useState, useRef,useEffect } from "react";
import Linkify from "react-linkify";
import {
  Button,
  Typography,
  Box,
  Grid2 as Grid,
  Skeleton,
} from "@mui/material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import "./style.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useTicketDetail } from "../../context/TicketDetailContext";
import { sanitizedHtml } from "../../utils/TicketDetailsCommonFunction";
import { componentDecorator } from "../../utils/common_functions";
import TextEditor from "../TextEditor";

const containsHTML = (str) => /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/i.test(str);

const renderDescription = (description) => {
  return containsHTML(description) ? (
    <Linkify componentDecorator={componentDecorator}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml(description) }} />
    </Linkify>
  ) : (
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
  );
};

const TicketDescription = ({
  title,
  displayBtn,
  buttonTitle,
  description,
  onChange,
  height,
  maxHeight,
}) => {
  const { isLoading, updateTicketDetails } = useTicketDetail();

  const [isEditing, setIsEditing] = useState(false);
  const contentRef = useRef(null);
  const editorRef = useRef(null);

  const handleBlur = (updatedContent) => {
    updateTicketDetails(title.toLowerCase(), updatedContent);

    setTimeout(() => {
      if (
        editorRef.current &&
        !editorRef.current.contains(document.activeElement)
      ) {
        setIsEditing(false);
      }
    }, 200);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target)
      ) {
        if (isEditing) {
          handleBlur(description);
          setIsEditing(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [description, isEditing]);



  return (
    <div className="description">
      <div className="title_button">
        <Typography
          style={{
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        <Button
          style={{
            display: displayBtn,
            width: "9rem",
            backgroundColor: "rgba(243, 235, 255, 1)",
            marginLeft: "1rem",
            borderRadius: "8px",
            color: "#000",
            textTransform: "none",
            justifyContent: "space-around",
          }}
        >
          <ReplayOutlinedIcon />
          {buttonTitle}
        </Button>
      </div>

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={70} />
      ) : (
        <Box
          className="description_box"
          style={{
            transition: "max-height 0.3s ease, height 0.3s ease",
            position: "relative",
          }}
        >
          <Grid
            sx={{
              padding: isEditing ? "0 " : "1rem",
              height: height,
              maxHeight: maxHeight,
              overflow: "auto",
              scrollbarWidth: "none",
              cursor: title == "Description" ? "pointer" : "",
            }}
            ref={contentRef}
            onClick={() => setIsEditing(title == "Description")}
          >
            {title == "Description" ? (
              <div ref={editorRef} tabIndex={0}>
                <TextEditor
                  initialValue={description}
                  onBlur={(updatedContent) => {
                    onChange(updatedContent);
                    handleBlur(updatedContent);
                  }}
                  onChange={(content) => onChange(content)}
                  placeholder={"Enter details ..."}
                  setIsEditing={setIsEditing}
                  readOnly={!isEditing}
                />
              </div>
            ) : (
              renderDescription(description)
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default TicketDescription;
