import { useRef, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  Grid2 as Grid,
} from "@mui/material";
import Linkify from "react-linkify";
import Typography from "@mui/material/Typography";
import "./style.css";
import {
  customFieldsIcons,
  sanitizedHtml,
} from "../../utils/TicketDetailsCommonFunction";
import { componentDecorator } from "../../utils/common_functions";
import TextEditor from "../TextEditor";
import { useTicketDetail } from "../../context/TicketDetailContext";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TicketFieldsModal from "../TicketFieldsModal";

const DataTable = ({ tableTitle, data, onChange }) => {
  const { isLoading, updateTicketDetails } = useTicketDetail();
  const [currentKey, setCurrentKey] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef(null);
  const tableRef = useRef(null);

  const handleBlur = (key, updatedContent) => {
    updateTicketDetails(key, updatedContent);
    setCurrentKey("");
    setIsEditing(false);
  };

  const handleClickOutside = (event) => {
    if (
      tableRef.current &&
      !tableRef.current.contains(event.target) &&
      editorRef.current &&
      !editorRef.current.contains(event.target) &&
      currentKey != ""
    ) {
      handleBlur(currentKey, currentData);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentData, currentKey]);

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={118} />;
  }
  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <TableContainer
      ref={tableRef}
      sx={{ border: "0.1px rgba(208, 210, 218, 1)", borderRadius: "3px" }}
    >
      <Table>
        <TableBody>
          {Object.entries(data)
            .filter(([key, value]) => key !== "")
            .map(([key, value]) => (
              <TableRow key={key}>
                <TableCell sx={{ border: "1px solid #ccc" }}>
                  <Grid container spacing={1}>
                    <Grid sx={{ color: "#676B7D" }}>
                      {" "}
                      {customFieldsIcons[key] || (
                        <TextFieldsIcon fontSize="small" />
                      )}
                    </Grid>
                    <Grid>
                      {" "}
                      <b>{key}</b>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell
                  className={`ticket_fields_tabelCell ${
                    currentKey == key ? "" : "not-editing"
                  }`}
                  sx={{
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    padding: "0",
                    maxHeight: "10em",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                  }}
                  onClick={() => {
                    if (tableTitle == "Escalation notes") {
                      setCurrentKey(key);
                    }
                  }}
                >
                  {tableTitle == "Escalation notes" && (
                    <div ref={editorRef} tabIndex={0}>
                      <TextEditor
                        initialValue={value}
                        onChange={(content) => {
                          onChange(key, content);
                          setCurrentData(content);
                        }}
                        setIsEditing={setIsEditing}
                        readOnly={currentKey != key}
                        placeholder={""}
                      />
                    </div>
                  )}

                  {tableTitle != "Escalation notes" && (
                    <Linkify componentDecorator={componentDecorator}>
                      <div
                        className="tabelCell-content"
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHtml(value),
                        }}
                      />
                    </Linkify>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TicketCustomFields = ({ tableTitle, data, onChange }) => {
  return (
    <div className="ticket_fields">
      <div className="ticket_fields_expand">
        <Typography
          style={{
            fontWeight: 500,
          }}
        >
          {tableTitle}
        </Typography>
        {tableTitle == "Escalation notes" ? (
          <TicketFieldsModal
            tableTitle={tableTitle}
            data={data}
            onChange={onChange}
          />
        ) : (
          ""
        )}
      </div>
      <div>
        <DataTable tableTitle={tableTitle} data={data} onChange={onChange} />
      </div>
    </div>
  );
};

export default TicketCustomFields;
