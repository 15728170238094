import { useRef, useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

const fonts = [
  "Rubik",
  "Arial",
  "Courier",
  "Georgia",
  "Tahoma",
  "Verdana",
  "Times",
];
const fontSizes = ["14px", "16px", "18px", "24px", "32px", "48px"];

const Font = Quill.import("formats/font");
Font.whitelist = fonts;
Quill.register(Font, true);

const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizes;
Quill.register(Size, true);

const TOOLBAR_OPTIONS = [
  [{ font: fonts }],
  [{ size: fontSizes }],
  [{ color: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link", "image"],
  ["blockquote", "code-block"],
];

export default function TextEditor(props) {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const reactQuillRef = useRef(null);
  const editorContainerRef = useRef(null);

  const [modules] = useState({
    toolbar: TOOLBAR_OPTIONS,
    clipboard: {
      matchVisual: true,
    },
  });
  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    const handleContentChange = () => {
      const editor = reactQuillRef.current.getEditor();
      editor.focus();
      const length = editor.getLength();
      if (length > 0) {
        editor.setSelection(length, length); // Place cursor at the end
      }
    };
    if (reactQuillRef?.current?.getEditor) {
      handleContentChange();
    }

    const editor = reactQuillRef.current.getEditor();
    editor.on("text-change", handleContentChange);

    return () => {
      editor.off("text-change", handleContentChange);
    };
  }, [reactQuillRef.current]);

  useEffect(() => {
    if (reactQuillRef?.current?.getEditor) {
      const editor = reactQuillRef.current.getEditor();
      editor.focus();
    }
  }, [value]);



  const handleChange = (content) => {
    setValue(content);
    setIsEdited(true);
    props.onChange(content);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div
      ref={editorContainerRef}
      style={{ height: props.height }}
     
    >
      <ReactQuill
       className={`quill-editor ${props.readOnly ? "hidden" : ""}`}
        ref={reactQuillRef}
        theme={props.theme}
        modules={modules}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus || props.handleFocus}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
      />
    </div>
  );
}
