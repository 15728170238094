import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  UsersAvailabilityCell,
  getRemainingTeamsCount,
  getUserAvatar,
  checkUserShift,
} from "../../utils/TableWithActionColumnFunctions";
import { StyledTableRow } from "../TableWithActionColumn";
import TableCell from "@mui/material/TableCell";
import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";
import UserRole from "../UserRole";
import StartEndTime from "../StartEndTime";

export const UsersTable = ({
  row,
  handleEditUser,
  handleChangeShift,
  index,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const teamsList = row.teams.map((item) => item.name);
  const subTeamsList = row.sub_teams.map((item) => item.name);

  return (
    <StyledTableRow
      tabIndex={-1}
      sx={{ cursor: "pointer" }}
      className={index % 2 ? "styledTableRow" : "nonStyledTableRow"}
    >
      <TableCell
        className="tableCells"
        sx={{
          padding: 0,
        }}
      >
        <div className="username">
          <div className="userAvatar">
            {getUserAvatar(row.user_avatar, row.first_name, row.color_code)}
          </div>
          <div className="userText">{row.username}</div>
        </div>
      </TableCell>

      <TableCell align="left" className="tableCells" padding="normal">
        {capitalizeFirstLetter(row.squad_name)}
      </TableCell>

      <TableCell align="left" className="tableCells" padding="normal">
        <div
          className="relatedTeamsContainer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            className="relatedTeamsFirstOption"
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {teamsList[0]}
          </div>
          <div className="remainingRelatedTeamsCount">
            {getRemainingTeamsCount(teamsList.length - 1, teamsList)}
          </div>
        </div>
      </TableCell>

      <TableCell align="left" className="tableCells" padding="normal">
        <div
          className="relatedSubTeamsContainer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            className="relatedSubTeamsFirstOption"
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {subTeamsList[0]}
          </div>
          <div className="remainingRelatedTeamsCount">
            {getRemainingTeamsCount(subTeamsList.length - 1, subTeamsList)}
          </div>
        </div>
      </TableCell>

      <TableCell align="center" className="tableCells">
        <UsersAvailabilityCell row={row} />
      </TableCell>

      <TableCell align="left" className="tableCells">
        {checkUserShift(row.user_shift)}
      </TableCell>

      <TableCell align="left" className="tableCells">
        <StartEndTime userShift={row.user_shift} />
      </TableCell>

      <TableCell align="left" className="tableCells">
        <UserRole row={row} />
      </TableCell>

      <TableCell align="left" className="tableCells">
        <IconButton
          aria-label="more"
          id="long-button"
          className="actionButtonMenu"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleOpenMenu}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          sx={{
            width: "12vw",
            padding: "0",
            height: "inherit",
            "& .MuiMenu-paper": {
              minHeight: "2.3rem",
              overflow: "hidden",
              borderRadius: "10px",
            },
            "& .MuiMenu-list": {
              padding: 0,
            },
          }}
        >
          <MenuItem
            onClick={(event) => handleEditUser(event, row)}
            sx={{
              padding: "0px 10px 4px 0px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#f3ebff",
                cursor: "pointer",
              },
            }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            Edit user
          </MenuItem>

          {!row.is_manager && (
            <MenuItem
              sx={{
                padding: "0px 10px 4px 0px",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#f3ebff",
                  cursor: "pointer",
                },
              }}
              onClick={(e) => handleChangeShift(e, row)}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
              >
                <VisibilityOutlinedIcon fontSize="small" />
              </IconButton>
              Change shift
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </StyledTableRow>
  );
};
