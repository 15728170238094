import React from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from "@mui/material/Checkbox";
import { DynamicList, RenderList } from "../DynamicList";

const NestedListContent = ({
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  selectedList,
  handleListSelect,
  displayMode,
  listSelected,
}) => {
  return (
    <List
      sx={{
        padding: "0",
        width: "100%",
        overflowY: displayMode == "modal" ? "scroll" : "none",

        scrollbarWidth: "none",
      }}
      component="nav"
    >
      {displayMode === "checkbox" ? (
        <ListSubheader
          id="nested-list-subheader"
          sx={{
            fontSize: "14px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          SELECT LIST(S)
        </ListSubheader>
      ) : (
        <ListSubheader id="nested-list-subheader" sx={{ fontSize: "16px" }}>
          ClickUp List
        </ListSubheader>
      )}

      <DynamicList
        key={0}
        items={spaces || []}
        fetchItems={() => fetchSpaceData()}
        handleItemClick={handleItemClick}
        openItems={openItems}
        dataType="spaces"
        listSelected={listSelected}
        renderItem={(space) => (
          <>
            <DynamicList
              key={space.id}
              sx={{ marginLeft: "2rem" }}
              items={folders[space.id] || []}
              fetchItems={() => fetchFolderData(space.id)}
              handleItemClick={handleItemClick}
              openItems={openItems}
              dataType="folders"
              listSelected={listSelected}
              renderItem={(folder) => (
                <DynamicList
                  key={folder.id}
                  sx={{ marginLeft: "2rem" }}
                  items={lists[folder.id] || []}
                  fetchItems={() => fetchListData(folder.id)}
                  handleItemClick={handleItemClick}
                  openItems={openItems}
                  dataType="lists"
                  renderItem={(list) => (
                    <div
                      style={
                        displayMode == "checkbox"
                          ? { display: "flex", alignItems: "center" }
                          : {}
                      }
                    >
                      {displayMode == "checkbox" ? (
                        <Checkbox
                          checked={selectedList.some(
                            (item) => item.list_id === list.id
                          )}
                          onChange={() =>
                            handleListSelect(list, {
                              spaceId: space.id,
                              folderId: folder.id,
                            })
                          }
                          sx={{
                            padding: "0",
                            marginLeft: "1.3rem",
                            color: "rgba(192, 195, 206, 1)",
                            "&.Mui-checked": {
                              color: "rgba(98, 0, 234, 1)",
                            },
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <RenderList
                        key={list.name}
                        onClick={() =>
                          handleListSelect(list, {
                            spaceId: space.id,
                            folderId: folder.id,
                          })
                        }
                        selectedList={selectedList}
                        list={list}
                        listSelected={listSelected}
                        idProp={space.id + folder.id}
                      />
                    </div>
                  )}
                />
              )}
            />
            <DynamicList
              key={space.id}
              sx={{ marginLeft: "2rem" }}
              items={listsWithoutFolder[space.id] || []}
              fetchItems={() => fetchListWithoutFolderData(space.id)}
              handleItemClick={handleItemClick}
              openItems={openItems}
              dataType="lists"
              renderItem={(list) => (
                <div
                  style={
                    displayMode == "checkbox"
                      ? { display: "flex", alignItems: "center" }
                      : {}
                  }
                >
                  {displayMode == "checkbox" ? (
                    <Checkbox
                      checked={selectedList.some(
                        (item) => item.list_id === list.id
                      )}
                      onChange={() =>
                        handleListSelect(list, {
                          spaceId: space.id,
                        })
                      }
                      sx={{
                        padding: "0",
                        marginLeft: "1rem",
                        color: "rgba(192, 195, 206, 1)",
                        "&.Mui-checked": {
                          color: "rgba(98, 0, 234, 1)",
                        },
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <RenderList
                    key={list.id}
                    onClick={() =>
                      handleListSelect(list, { spaceId: space.id })
                    }
                    selectedList={selectedList}
                    list={list}
                    listSelected={listSelected}
                    idProp={space.id}
                  />
                </div>
              )}
            />
          </>
        )}
      />
    </List>
  );
};

export default NestedListContent;
