import { Button } from "@mui/material";
import "./style.css";
import ErrorPageExclaim from "../../assets/error_page_exclaim.svg";

export const ErrorComponent = ({
  errorMessage,
  handleRefresh,
  firstButtonText,
}) => {
  return (
    <div className="errorComponent">
      <div className="yellowErrorExclaim">
        <img src={ErrorPageExclaim} alt="error" />
      </div>
      <div className="topErrorMessage">Something went wrong.</div>
      <div className="errorMessage">{errorMessage}</div>

      <div className="errorBtnsContainer">
        <Button
          sx={{
            borderRadius: "0.5rem",
            background: "#00E676",
            color: "black",
            textTransform: "capitalize",
            width: "9.125rem",
            gap: "0.25rem",
            fontSize: "12px",
            "&:hover": {
              background: "#00E676",
              color: "black",
            },
          }}
          onClick={handleRefresh}
          disabled={!firstButtonText} 
        >
          {firstButtonText}
        </Button>
      </div>
    </div>
  );
};
