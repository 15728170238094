import { TableCell, TableHead, TableRow } from "@mui/material";
import { headerWidth } from "../../utils/TableWithActionColumnFunctions";

const TablesHead = ({ headCells }) => {
  return (
    <TableHead className="tableHead">
      <TableRow sx={{ border: 1 }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              fontSize: "12px",
              fontFamily: "Rubik",
              color: "#3C3E49",
              border: "0.5px solid var(--Base-Blue-grey-3, #DFE0E6)",
              backgroundColor: "#f5f5f5",
              MinWidth: headerWidth(headCell.label),
              padding: headCell.label == "Username" ? "0px 5px 0px 15px " : "",
            }}
          >
            {" "}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TablesHead;
