import { Card } from "../DashboardCards";

const ClosedTickets = ({
  isClosedTicketsLoading,
  getLoader,
  closedTicketsError,
  closedTicketsCards,
  totalClosedTicketsCount,
  parameterString,
  dateUserSelectedFilter,
}) => {
  return (
    <div className="closed_tickets_cards_section">
      <div className="dashboard_cards_heading">Closed tickets SLA status</div>
      {isClosedTicketsLoading && getLoader()}
      {!closedTicketsError && !isClosedTicketsLoading && (
        <div className="dashboard_closed_cards_row">
          {closedTicketsCards
            ?.filter((cardInfo) => cardInfo.name)
            .map((cardInfo, i) => (
              <Card
                key={`${i}-${cardInfo.name}`}
                cardIndex={i}
                cardInfo={cardInfo}
                totalCount={totalClosedTicketsCount}
                parameterString={parameterString}
                dateUserSelectedFilter={dateUserSelectedFilter}
                shouldClickBeDisabled={false}
                closedOrOpen="closed"
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default ClosedTickets;
