import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import "./style.css";

const TicketCommentMail = () => {
  return (
    <TextField
      className="comment_mail_field"
      id="outlined-basic"
      variant="outlined"
      placeholder="Write a comment..."
      fullWidth
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <Button
                className="comment_mail_button"
                size="small"
                variant="contained"
               
              >
                Comment
              </Button>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default TicketCommentMail;
