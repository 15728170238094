import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./style.css";
import {
  formatDateValue,
  updateUrlWithFilters,
} from "../../utils/clearAllSelectedFilters";
import { findDashboardDateValue } from "../../utils/clearAllSelectedFiltersForDashboard";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import updateLocale from "dayjs/plugin/updateLocale";
import { getMinMaxDate } from "../../utils/common_functions";
import { errorNotify } from "../ToastComponent";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "70rem",
  minWidth: "50rem",
  height: "32rem",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const modalHeadingStyle = {
  color: "#3C3E49",
  fontFamily: "Rubik,sans-serif",
  fontSize: "1.3rem",
  fontStyle: "normal",
  lineHeight: "1.75rem",
  marginBottom: "1rem",
};

const startEndDateStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const startEndDateTextStyle = {
  color: "#3C3E49",
  fontFamily: "Rubik,sans-serif",
  fontWeight: "500",
  paddingRight: "1rem",
};

const applyResetStyle = {
  marginTop: "2rem",
  display: "inline-flex",
  justifyContent: "flex-end",
  width: "100%",
};

const applyButtonStyle = {
  borderRadius: "0.5rem",
  background: "#00E676",
  width: "5.5rem",
  height: "2.2rem",
  textTransform: "none",
  fontWeight: 500,
  cursor: "pointer",
};

const resetButtonStyle = {
  borderRadius: "0.5rem",
  background: "#ECEDF0",
  width: "5.5rem",
  height: "2.2rem",
  textTransform: "none",
  fontWeight: 500,
};

const boxStyle = {
  width: "100%",
  height: "18rem",
  marginTop: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  backgroundColor: "#f0f0f0",
  opacity: "0.4",
  cursor: "pointer",
  fontSize: "1.2rem",
  fontFamily: "Rubik",
};

const CustomDatePicker = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  calendarStartDay,
}) => {
  return (
    <>
      <Typography style={startEndDateTextStyle}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            enableAccessibleFieldDOMStructure
            value={dayjs(value)}
            calendarStartDay={calendarStartDay}
            minDate={minDate ? dayjs(minDate) : undefined}
            maxDate={maxDate ? dayjs(maxDate) : undefined}
            onChange={onChange}
            format={"MMM D, YYYY"}
            slotProps={{
              popper: {
                sx: {
                  ".MuiDateCalendar-root ": {
                    overflow: "hidden",
                    minHeight: "18rem",
                    width: "22rem",
                  },
                  ".MuiPickersCalendarHeader-root": {
                    marginTop: "0.2rem",
                  },
                  ".MuiPickersCalendarHeader-labelContainer": {
                    cursor: "default",
                    fontFamily: "Rubik",
                  },
                  ".MuiPickersCalendarHeader-switchViewButton": {
                    display: "none",
                  },
                  ".MuiPickersPopper-paper": {
                    height: "18rem",
                    marginLeft: "-6rem",
                    marginTop: "1rem",
                  },
                },
              },
              textField: {
                sx: {
                  ".MuiPickersOutlinedInput-root": {
                    height: "2.5rem",
                    overflow: "hidden",
                    width: "16rem",
                  },
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default function DateFilterModal({
  modalHeading,
  dateFilter,
  setDateUserSelectedFilter,
  setDateFilter,
  dateUserSelectedFilter,
  isCustomDateDropdownSelected,
  userSelectedFilter,
}) {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [resetButtonStatus, setResetButtonStatus] = React.useState(false);

  const isDateChangedFromModal = React.useRef(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatStartEndDate = (dateString) => {
    const formattedDate = new Date(dateString);
    const day = formattedDate.getUTCDate().toString().padStart(2, "0");
    const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = formattedDate.getUTCFullYear().toString();

    return `${year}-${month}-${day}`;
  };

  const handleApply = (e) => {
    const dateDifference = Math.abs(
      new Date(endDate).getTime() - new Date(startDate).getTime()
    );
    const dayDifference = dateDifference / (1000 * 3600 * 24);

    if (dayDifference > 30) {
      errorNotify("Please select a date range within 30 days.");
      return;
    }

    setDateFilter("Custom date");

    let formattedStartDate = startDate ? formatDateValue(startDate) : "";
    let formattedEndDate =
      endDate && endDate !== ""
        ? formatDateValue(endDate)
        : findDashboardDateValue("Today");

    setDateUserSelectedFilter({
      From: formattedStartDate,
      To: formattedEndDate,
    });
    userSelectedFilter["startDate"] = formattedStartDate;
    userSelectedFilter["endDate"] = formattedEndDate;

    updateUrlWithFilters(
      userSelectedFilter,
      "Custom date",
      formattedStartDate,
      formattedEndDate
    );
    setResetButtonStatus(false);
    handleClose();
  };

  const getTextFromDate = (dateString, options) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    return date.toLocaleDateString("en-US", options);
  };

  const getFormattedModalDateString = (dateString) => {
    const [month, day] = dateString.split(" ");

    const convertedMonth =
      month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();

    return `${convertedMonth} ${day}`;
  };

  const getModalText = () => {
    const startDateText = getTextFromDate(userSelectedFilter["startDate"], {
      month: "short",
      day: "2-digit",
    });
    const endDateText =
      getTextFromDate(userSelectedFilter["endDate"], {
        month: "short",
        day: "2-digit",
      }).length > 0
        ? ` - ${getFormattedModalDateString(
            getTextFromDate(userSelectedFilter["endDate"], {
              month: "short",
              day: "2-digit",
            })
          )}`
        : "";
    return [startDateText, endDateText].join("");
  };

  const handleReset = (e) => {
    modalWindowDatesSet(dateFilter);
    setResetButtonStatus(false);
  };

  const urlParams = new URLSearchParams(window.location.search);

  const modalWindowDatesSet = (dateFilter) => {
    userSelectedFilter["spanKey"] = dateFilter;

    switch (dateFilter) {
      case "Today":
      case "Yesterday":
      case "Last 7 days":
      case "Last 14 days":
      case "Last 30 days": {
        const start = findDashboardDateValue(dateFilter);
        const end = findDashboardDateValue("Today");

        setStartDate(start);
        setEndDate(end);

        userSelectedFilter["startDate"] = start;
        userSelectedFilter["endDate"] = end;

        setDateUserSelectedFilter({ From: start, To: end });
        break;
      }

      case "Custom date": {
        const from =
          dateUserSelectedFilter.From?.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.From)
            : urlParams.get("custom_date_from");

        const to =
          dateUserSelectedFilter.To?.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.To)
            : urlParams.get("custom_date_to");

        setStartDate(from);
        setEndDate(to);

        userSelectedFilter["startDate"] = from;
        userSelectedFilter["endDate"] = to;

        break;
      }
    }
  };

  React.useEffect(() => {
    if (isCustomDateDropdownSelected.current) {
      handleOpen();
      isCustomDateDropdownSelected.current = false;
    }
    modalWindowDatesSet(dateFilter);
  }, [dateFilter]);

  React.useEffect(() => {
    if (!isDateChangedFromModal.current) {
      setDateUserSelectedFilter({
        From: startDate || "",
        To: endDate || "",
      });
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    setModalButtonText(getModalText());
  }, [dateUserSelectedFilter, startDate, endDate]);

  return (
    <div className="open_modal_container">
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          padding: "0.5rem",
          color: "#3c3e49",
          font: "400 14px/143% Rubik, sans-serif",
        }}
      >
        {modalButtonText}
        <ArrowDropDownIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid sx={style}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={modalHeadingStyle}
            >
              {modalHeading}
            </Typography>
            <Box
              component="div"
              sx={{
                width: "2rem",
                height: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid rgba(0, 0, 0, 0.20)",
                borderRadius: "8px",
              }}
            >
              <CloseOutlinedIcon
                sx={{
                  width: "1.3rem",
                  height: "1.3rem",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </Box>
          </Grid>
          <Grid sx={{ ...startEndDateStyle, alignItems: "center" }} container>
            <CustomDatePicker
              label="Start Date"
              value={startDate}
              onChange={(newDate) => {
                isDateChangedFromModal.current = true;
                setResetButtonStatus(true);
                setStartDate(newDate);
                userSelectedFilter["Custom date"].From =
                  formatStartEndDate(newDate);
              }}
              calendarStartDay={2}
              maxDate={dayjs(
                endDate
                  ? getMinMaxDate(endDate, 0)
                  : getMinMaxDate(startDate, 0)
              )}
            />

            <CustomDatePicker
              label="End Date"
              value={endDate}
              onChange={(newDate) => {
                isDateChangedFromModal.current = true;
                setResetButtonStatus(true);
                setEndDate(newDate);
                userSelectedFilter["Custom date"].To =
                  formatStartEndDate(newDate);
              }}
              calendarStartDay={1}
              minDate={startDate ? getMinMaxDate(startDate, 0) : undefined}
              maxDate={startDate ? getMinMaxDate(startDate, 30) : undefined}
            />

            <Box sx={boxStyle}>
              Click on <InsertInvitationIcon fontSize="medium" /> to select date
              range
            </Box>
          </Grid>
          <Box sx={applyResetStyle} className="btnsContainerForBox">
            <Button style={applyButtonStyle} onClick={handleApply}>
              Apply
            </Button>
            <Button
              style={
                !resetButtonStatus
                  ? {
                      ...resetButtonStyle,
                      pointerEvents: "auto",
                      cursor: "not-allowed",
                    }
                  : resetButtonStyle
              }
              onClick={handleReset}
              disabled={!resetButtonStatus}
            >
              Reset
            </Button>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
}
