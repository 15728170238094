import { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextEditor from "../TextEditor";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Divider } from "@mui/material";
import "./style.css";
import { useTicketDetail } from "../../context/TicketDetailContext";

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  height: "36rem",
  bgcolor: "background.paper",
  borderRadius: "0.5rem",
  overflow: "hidden",
};

const TicketFieldsModal = ({ tableTitle, data, onChange }) => {
  const { updateTicketDetails } = useTicketDetail();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentKey, setCurrentKey] = useState("");
  const [textEscalation, setTextEscalation] = useState({
    "L1 escalation notes": "",
    "L2 escalation notes": "",
    "Resolution notes": "",
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const editorRef = useRef(null);
  const modalContentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      event.target &&
      modalContentRef?.current?.contains(event.target) &&
      editorRef.current &&
      !editorRef.current.contains(event.target)
    ) {
      setIsEditing(false);
      setCurrentKey("");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setCurrentKey("");
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (
        editorRef.current &&
        !editorRef.current.contains(document.activeElement)
      ) {
        setIsEditing(false);
        setCurrentKey("");
      }
    }, 200);
  };

  const handleChange = (key, content) => {
    setTextEscalation((prev) => ({
      ...prev,
      [key]: content,
    }));
    setIsSaveDisabled(false);
    onChange(key, content);
  };

  const handleSave = () => {
    Object.entries(textEscalation).forEach(([key, value]) => {
      if (data[key] != undefined && value != "") {
        updateTicketDetails(key, value);
      }
    });

    setIsEditing(false);
    setCurrentKey("");
    setOpen(false);
    setIsSaveDisabled(true);
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <OpenInNewOutlinedIcon />
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        ref={modalContentRef}
      >
        <Box sx={style}>
          <div className="modal-header">
            <Typography
              style={{
                fontWeight: 500,
              }}
            >
              {tableTitle}
            </Typography>

            <CloseOutlinedIcon
              sx={{
                width: "1.3rem",
                height: "1.3rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </div>

          <Divider />

          <div className="modal-content">
            {data &&
              Object.entries(data).map(([key, value]) => (
                <div className="escalation-editor" key={key}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      mb: "0.4rem",
                    }}
                  >
                    {key}
                  </Typography>
                  <div
                    className={`modal-content-editor ${
                      currentKey == key ? "" : "not-editing"
                    }`}
                    onClick={() => {
                      setIsEditing(true);
                      setCurrentKey(key);
                    }}
                  >
                    <div ref={editorRef} tabIndex={0}>
                      <TextEditor
                        initialValue={value || textEscalation[key]}
                        onBlur={(updatedContent) => {
                          handleChange(key, updatedContent);
                          handleBlur();
                        }}
                        onChange={(content) => handleChange(key, content)}
                        setIsEditing={setIsEditing}
                        readOnly={currentKey != key}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <Divider />

          <div className="modal-button">
            <Button
              data-testid="goBack-btn"
              variant="contained"
              disabled={isSaveDisabled}
              sx={{
                background: " #F3EBFF",
                color: "#000",
                borderRadius: "0.5rem",
                textTransform: "none",
                "&:hover": {
                  background: " #F3EBFF",
                },
                "&.Mui-disabled": {
                  background: "#F3EBFF",
                  color: "#929292",
                  opacity: 1,
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TicketFieldsModal;
