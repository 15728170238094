import React from "react";
import "./style.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export const WarningModal = ({
  styleContainer,
  open,
  src,
  title,
  displayMessage,
  closeButtonTitle,
  functionalButtonTitle,
  handleConfirm,
  handleClose,
  mainButtonColor,
}) => {
  return (
    <div className="container" style={styleContainer}>
      {src}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 470,
            height: 190,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "0.5rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {displayMessage}
          </Typography>
          <div className="btn-container">
            <Button
              data-testid="goBack-btn"
              variant="contained"
              sx={{
                background: " var(--Base-Grey-3, #EEE)",
                color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
                "&:hover": {
                  background: " var(--Base-Grey-3, #D6D6D6)",
                },
              }}
              onClick={handleClose}
            >
              {closeButtonTitle}
            </Button>

            <Button
              data-testid="logout-btn"
              variant="contained"
              sx={{
                background: mainButtonColor,
                color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
                "&:hover": {
                  background: " var(--Interactive-Bg-Err, #FF9F97)",
                },
              }}
              onClick={handleConfirm}
            >
              {functionalButtonTitle}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
