import { useEffect, useState, useCallback } from "react";
import useWebSocket from "react-use-websocket";

const useWebSocketService = (getWebSocketUrl) => {
  const [lastJsonMessage, setLastJsonMessage] = useState(null);

  // Memoize the WebSocket URL to ensure it remains stable.
  const webSocketUrl = useCallback(() => getWebSocketUrl, [getWebSocketUrl]);

  // Invoke `useWebSocket` to open the WebSocket connection.
  const {
    lastJsonMessage: currentJsonMessage
  } = useWebSocket(webSocketUrl, {
    onOpen: () => console.log("WebSocket connected!"),
    onError: (error) => console.error("WebSocket error:", error),
    onMessage: (message) => {
      try {
        const parsedMessage = JSON.parse(message.data);
        setLastJsonMessage(parsedMessage);
      } catch (err) {
        console.error("Error parsing WebSocket message:", err);
      }
    },
    onClose: (event) => {
      console.warn("WebSocket closed:", event.code, event.reason);
    },
    shouldReconnect: () => true, // Auto reconnect on connection lost
  });

  useEffect(() => {
    if (!getWebSocketUrl) {
      console.error("WebSocket URL is required");
      return;
    }
    // Update the state when new JSON message is received.
    if (currentJsonMessage) {
      setLastJsonMessage(currentJsonMessage);
    }
  }, [currentJsonMessage]);

  return lastJsonMessage;
};

export default useWebSocketService;
