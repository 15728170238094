import Grid from "@mui/material/Grid2";

const UserRole = ({ row }) => {
  const role = row.is_manager ? "Manager" : "User";

  return (
    <Grid container className={`users${role}`}>
      <Grid style={{ paddingLeft: "8px", fontFamily: "sans-serif" }}>
        {" "}
        {role}
      </Grid>
    </Grid>
  );
};

export default UserRole;
