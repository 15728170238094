import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTicketDetail } from "../../context/TicketDetailContext";
import TicketEscalatePopup from "../TicketEscalatePopup";
import CloseIcon from "@mui/icons-material/Close";
import { requiredEscalationNote } from "../../utils/EscalationValidations";

const textFieldStyle = {
  width: "100%",
  borderRadius: "4px",
  padding: "0.2rem",
  "& .MuiInputBase-root::before, & .MuiInputBase-root::after": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    maxHeight: "36px !important",
    fontSize: "14px",
    color: "#3c3e49",
  },
  "& .MuiSelect-select span::before": {
    content: '"Empty"',
    color: "#9b9fb1",
    fontSize: "0.9rem",
    fontFamily: "Rubik",
  },
  "& .MuiSelect-icon": { display: "none" },
  "&:hover": { backgroundColor: "#F8F9FA" },
};

const TicketSelectField = ({
  type,
  value,
  optionsMapper,
  listData,
  updateFunction,
  isStatus = false,
  open,
  notes,
  updateInputBox,
  subTeamUpdate,
  setSubTeamUpdate,
  textEscalation,
  setTextEscalation,
  handleNotesChange,
  handleClose,
  handleProceed
}) => {
  const { ticketData, focusType, showClear, setFocusType, setShowClear } =
    useTicketDetail();

  const handleFocus = () => {
    setFocusType(type);
    setShowClear(true);
  };

  const handleBlur = () => {
    setFocusType(null);
    setShowClear(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (type === "sub_team") {
      updateFunction(listData.find((item) => item.name === inputValue) || null);
    } else {
      updateFunction(inputValue);
    }
  };

  return (
    <>
      <TextField
        data-testid="select"
        select
        variant="standard"
        value={isStatus ? updateInputBox || "" : value || ""}
        sx={textFieldStyle}
        fullWidth
        {...(!isStatus && { onChange: handleInputChange })}
        {...(!isStatus && { onMouseEnter: handleFocus })}
        {...(!isStatus && { onMouseLeave: handleBlur })}
        {...(type === "sub_team" && {
          slotProps: {
            input: {
              endAdornment:
                showClear &&
                value &&
                focusType === type &&
                ticketData?.team_configs?.enforce_sub_team_for_level.includes(
                  updateInputBox
                ) ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => updateFunction("")}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            },
          },
        })}
      >
        {listData.length > 0 ? (
          listData.map(optionsMapper)
        ) : (
          <Typography sx={{ color: "#9b9fb1" }}>Empty</Typography>
        )}
      </TextField>
      {type == "status" && (
        <TicketEscalatePopup
          prevStatus={value}
          status={updateInputBox}
          notes={notes}
          handleNotesChange={handleNotesChange}
          openModal={open}
          requiredEscalationNote={requiredEscalationNote(value, updateInputBox)}
          setTextEscalation={setTextEscalation}
          textEscalation={textEscalation}
          setSubTeamUpdate={setSubTeamUpdate}
          subTeamUpdate={subTeamUpdate}
          handleClose={handleClose}
          handleProceed={handleProceed}
        />
      )}
    </>
  );
};

export default TicketSelectField;
