import { useEffect, useState } from "react";
import "./styles.css";
import NoRecords from "../../assets/empty state - no search result.svg";
import Button from "@mui/material/Button";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { MaterialTable } from "../MaterialTable";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const TicketsTable = (props) => {
  const debouncedParameters = useDebounce(props.parameters, 300);

  useEffect(() => {
    const searchParams = window.location.search;

    if (searchParams) {
      if (debouncedParameters) {
        props.setRowData([]);
        props.fetchDataForTicketsTable(
          `${baseURL}v2/ticket/?limit=15${debouncedParameters}`
        );
      }
    } else {
      props.fetchDataForTicketsTable(
        `${baseURL}v2/ticket/?limit=15${props.parameters}`
      );
    }
  }, [debouncedParameters, props.parameters]);

  const tableProps = {
    url: props.url,
    dataIsLoading: props.isLoading,
    parameters: props.parameters,
    fetchData: props.fetchDataForTicketsTable,
    tableData: props.rowData,
    tableHeadCells: props.tableHeadCells,
    tableKeys: props.tableKeys,
    display: "none",
    handleOpenTicket: props.handleOpenTicket,
    orderedColumn: props.orderedColumn,
    handleSetSelectedColumn: props.handleSetSelectedColumn,
  };

  return (
    <div>
      <div className="count_tickets">
        {props.countTickets > 0 && `${props.countTickets} tickets`}
      </div>
      <MaterialTable {...tableProps} tableType="ticketSummary" />
      <div className="no_records_found" style={{ display: "none" }}>
        <img
          src={NoRecords}
          alt={"Norecord"}
          style={{ width: "5rem", height: "5rem" }}
        />
        <div
          style={{
            color: "var(--Base-Blue-grey-10, #3C3E49)",
            fontFamily: "Rubik",
            fontSize: "1.25rem",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "2rem",
          }}
        >
          No matching tickets found.
        </div>
        <Button
          style={{
            textTransform: "none",
            color: "var(--Interactive-Bg-Purple-5, #6200EA)",
            fontFamily: "Rubik",
            fontWeight: "500",
            lineheight: "1.5rem",
          }}
          variant="text"
          onClick={props.clearAllSelectedFilters}
        >
          Clear filters
        </Button>
      </div>
    </div>
  );
};
