import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { updateDateToHumanFormatTicketConfig } from "../../utils/common_functions";
import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";

const fetchAndUpdateTicketConfigData = (incomingWSData, ticketData) => {
  if (incomingWSData != null) {
    const { event_type, value } = incomingWSData;
    switch (event_type) {
      case "due_date_changed":
        ticketData.due_date = value;
        break;
      case "status_changed":
        case "task_closed":
        ticketData.status = value;
        break;
      case "priority_changed":
        ticketData.priority = value;
        break;
      case "sub_team_updated":
        ticketData.sub_team = value;
        break;
      case "assignee_changed":
      case "assignee_added":
      case "assignee_removed":
        ticketData.assignee = value;
        break;
      default:
        break;
    }
  }
  return ticketData;
};

export const getTicketConfigDetails = (
  ticketData,
  subTeams,
  teamStatus,
  updatePriority,
  updateSubTeam,
  updateTicketDetails,
  incomingWSData
) => {
  if (!ticketData || Object.keys(ticketData).length === 0) {
    return [];
  }

  const ticketDataWithWS = fetchAndUpdateTicketConfigData(
    incomingWSData,
    ticketData
  );
  return [
    {
      id: "status",
      name: "Status",
      icon: RadioButtonCheckedOutlinedIcon,
      type: "status",
      value: capitalizeFirstLetter(ticketDataWithWS?.status),
      listData: teamStatus,
      updateFunction: updateTicketDetails,
    },
    {
      id: "issue_category",
      name: "Issue category",
      icon: FolderOpenOutlinedIcon,
      type: "text",
      value: ticketDataWithWS?.issue_category,
      listData: [],
    },
    {
      id: "due_date",
      name: "Due date",
      icon: CalendarTodayOutlinedIcon,
      type: "text",
      value: updateDateToHumanFormatTicketConfig(ticketDataWithWS?.due_date),
      listData: [],
    },
    {
      id: "assignee",
      name: "Assignee",
      icon: PersonOutlineOutlinedIcon,
      type: "assignee",
      value: ticketDataWithWS?.assignee,
      listData: [
        {
          url: ticketDataWithWS?.user_avatar,
          username: ticketDataWithWS?.assignee,
          color_code: ticketDataWithWS?.assignee_color_code,
        },
      ],
    },
    {
      id: "priority",
      name: "Priority",
      icon: TourOutlinedIcon,
      type: "priority",
      value: ticketDataWithWS?.priority,
      listData: [
        {
          id: 1,
          name: "urgent",
        },
        {
          id: 2,
          name: "high",
        },
        {
          id: 3,
          name: "normal",
        },
        {
          id: 4,
          name: "low",
        },
      ],
      updateFunction: updatePriority,
    },
    {
      id: "team",
      name: "Team",
      icon: PeopleAltOutlinedIcon,
      type: "text",
      value: ticketDataWithWS?.team,
      listData: [],
    },
    {
      id: "tags",
      name: "Tags",
      icon: LocalOfferOutlinedIcon,
      type: "tag",
      value: ticketDataWithWS?.tags,
      listData: [],
    },
    {
      id: "sub_team",
      name: "Sub team",
      icon: PeopleAltOutlinedIcon,
      type: "sub_team",
      value: ticketDataWithWS?.sub_team,
      listData: subTeams,
      updateFunction: updateSubTeam,
    },
  ];
};
