import Chip from "@mui/material/Chip";
import { filterOptions } from "./filterOptions";
import "./style.css";

const GroupByFilter = ({ groupBy, handleToggleGroupBy }) => {
  return (
    <div className="groupby_chips">
      {filterOptions.map((option) => {
        const isGrouped = groupBy?.includes(option.id);

        return (
          <Chip
            key={option.id}
            variant="outlined"
            label={`Group: ${option.label}`}
            onClick={() => handleToggleGroupBy(option)}
            sx={{
              borderRadius: "0.25rem",
              height: "2.4rem",
              border: `1px solid ${
                isGrouped ? "#B388FF" : "rgba(155, 159, 177, 1)"
              }`,
              fontSize: "14px",
              fontWeight: isGrouped ? 500 : "normal",
              marginRight: "1rem",
              backgroundColor: isGrouped ? "#F3EBFF" : "transparent",
              color: isGrouped ? "#6200EA" : "#3C3E49",
              "&:hover": {
                backgroundColor: `${
                  isGrouped ? "#F3EBFF" : "transparent"
                } !important`,
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default GroupByFilter;
