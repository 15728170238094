import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { checkPriority } from "../utils/TicketsTableFunctions";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Typography } from "@mui/material";
import UserIcon from "../components/UserIcon";
import { Grid } from "@mui/system";
import { ChipWithMenu } from "../components/TeamsCountChips";
import { capitalizeFirstLetter } from "./clearAllSelectedFilters";
import sanitizeHtml from "sanitize-html";

export const tagChips = (value) => {
  const displayValues = value?.slice(0, 2);
  const remainingValues = value?.slice(2);
  return (
    <Grid container spacing={1}>
      {displayValues.map((key, index) => (
        <Grid key={index} className="ticket_tag">
          {key}
        </Grid>
      ))}
      {remainingValues.length > 0 ? (
        <ChipWithMenu
          menuItemList={remainingValues}
          count={remainingValues.length}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export const renderSwitch = (type, value, listData) => {
  switch (type) {
    case "assignee":
      return (
        <UserIcon
          image_url={listData[0]["url"]}
          username={listData[0]["username"]}
          colorCode={listData[0]["color_code"]}
        />
      );
    case "text":
      return <Typography>{value}</Typography>;
    case "priority":
      return checkPriority(value, true);
    case "tag":
      return tagChips(value);
    case "status":
    case "assignee_added":
    case "assignee_removed":
    case "assignee_changed":
    case "custom_field_updated":
      return <Typography>{capitalizeFirstLetter(value)}</Typography>;

    default:
      return <Typography>Empty</Typography>;
  }
};

export const iconsMap = {
  status_changed: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  assignee_added: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_changed: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_removed: <PersonOutlineOutlinedIcon fontSize="small" />,
  priority_changed: <TourOutlinedIcon fontSize="small" />,
  custom_field_updated: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  team: <PeopleAltOutlinedIcon fontSize="small" />,
  sub_team_updated: <PeopleAltOutlinedIcon fontSize="small" />,
  issue_category: <FolderOpenOutlinedIcon fontSize="small" />,
  due_date_changed: <CalendarTodayOutlinedIcon fontSize="small" />,
  tag: <LocalOfferOutlinedIcon fontSize="small" />,
  task_closed: <RadioButtonCheckedOutlinedIcon fontSize="small" />
};

export const customFieldsIconsMap = {
  url: <LanguageIcon fontSize="small" />,
  dropdown: <ArrowDropDownSharpIcon fontSize="small" />,
  text: <TextFieldsIcon fontSize="small" />,
  email: <MailOutlineOutlinedIcon fontSize="small" />,
};

export const customFieldsIcons = {
  "L1 escalation notes": <TextFieldsIcon fontSize="small" />,
  "L2 escalation notes": <TextFieldsIcon fontSize="small" />,
  "Ide url": <LanguageIcon fontSize="small" />,
  "Ninja email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Pm dashboard url": <LanguageIcon fontSize="small" />,
  "Sensei email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Infra pipeline url": <LanguageIcon fontSize="small" />,
};

export const sanitizedHtml = (data) =>
  sanitizeHtml(data, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ["src", "width"],
      p: ["style", "data-block-id"],
    },
  });
