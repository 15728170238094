import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { customFetch } from "../../services/requestServices";
import { useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useURLInfo } from "../../utils/useURLInfo";
import {
  handleParentClick,
  clearAllSelectedFilters,
  updateUserSelectedFilter,
  handleChildFilterUnchecked,
  displaySelectedFilters,
  handleParentFilterUnchecked,
  generateParamString,
  capitalizeFirstLetter,
  FindChildFilterSubElements,
  getOverallTicketType,
  FilterSubOptions,
  updateUrlWithFilters,
  storedFilters,
} from "../../utils/clearAllSelectedFilters";
import {
  handleInputChange,
  getSubFilterClassName,
  handleHideAllSuboptions,
  removeSpaceswithUnderScores,
  findCheckedStatusFromUrlParams,
  showFiltersDropdown,
} from "../../utils/TicketsTableFunctions";
import Header from "../../components/Header";
import { defaultFiltersForTicketSummary } from "./data";
import { TicketsTable } from "../../components/TicketsTable";
import { handleClickOutsideFilters } from "../../utils/common_functions";
import GroupByFilter from "../../components/GroupByFilter";
import GroupByTable from "../../components/GroupByTable";
import {
  ticketHeadCells,
  ticketkeys,
} from "../../utils/DashboardModalConstants";

const TicketSummaryScreenComponent = () => {
  const [allFilters, setAllFilters] = useState(() => {
    const storedAllFilters = sessionStorage.getItem("allFilters");
    return storedAllFilters == "undefined" || "null"
      ? {}
      : JSON.parse(storedAllFilters);
  });
  const [filterOptions, setFilterOptions] = useState([]);
  const [parameterString, setParameterString] = useState("");
  const [selectedColumn, setSelectedColumn] = useState({
    columnName: "",
    forward: false,
  });
  const [userSelectedFilter, setUserSelectedFilter] = useState(() => {
    const storedFilters = sessionStorage.getItem("storedFilters");
    const additionalDateFields = {
      date: {
        ["Date created"]: {
          From: "",
          To: "",
        },
        ["Date due"]: {
          From: "",
          To: "",
        },
      },
      closedDate: {
        From: "",
        To: "",
      },
    };

    return storedFilters
      ? { ...JSON.parse(storedFilters), ...additionalDateFields }
      : defaultFiltersForTicketSummary;
  });
  const [searchQuery, setSearchQuery] = useSearchParams();
  const [inputValue, setInputValue] = useState(searchQuery.get("search") || "");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [displayDownloadProgress, setDisplayDownloadProgress] =
    useState("none");

  const [rowData, setRowData] = useState([]);
  const [groupBy, setGroupBy] = useState(["status"]);
  const [groupByRowData, setGroupByRowData] = useState([]);
  const [url, setUrl] = useState(`${baseURL}v2/ticket/?limit=15`);
  const [isLoading, setIsLoading] = useState(false);

  const [countTickets, setCountTickets] = useState(null);

  const [groupByUrl, setGroupByUrl] = useState(
    `${baseURL}v2/ticket/?limit=15&grouped_by=${groupBy}`
  );
  const prevFetchUrl = useRef(null);

  const urlUserSelectedParams = useURLInfo();

  const fetchFiltersDataCount = useRef(0);

  const updateHandleInputChange = (updatedInput) => {
    setInputValue(updatedInput);
  };

  const handleClearSearch = () => {
    setInputValue("");
    setUserSelectedFilter((prevFilters) => ({
      ...prevFilters,
      search: "",
    }));
  };

  const getSearchParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("search") || "";
  };

  useEffect(() => {
    const initialSearch = getSearchParamFromURL();
    if (initialSearch) {
      setInputValue(initialSearch);
      setDebouncedInputValue(initialSearch);
    }
  }, []);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  const handleSetSelectedColumn = (newSelectedColumnObject) => {
    setSelectedColumn(newSelectedColumnObject);
  };

  useEffect(() => {
    const sortDirection = selectedColumn.forward ? "" : "-";

    const columnNameWithSign = `${sortDirection}${removeSpaceswithUnderScores(
      selectedColumn.columnName.toLowerCase()
    )}`;

    const searchParams = new URLSearchParams(parameterString);

    if (selectedColumn.columnName) {
      setUrl("");
      setRowData([]);
      window.scrollTo(0, 0);
      searchParams.set("ordering", columnNameWithSign);
    }

    const updatedParamString = `&${searchParams.toString()}`;
    setParameterString(updatedParamString);
    searchParams.delete("ordering");
  }, [selectedColumn, debouncedInputValue, parameterString]);

  useEffect(() => {
    if (inputValue) {
      setSearchQuery({ search: inputValue });
      userSelectedFilter["search"] = inputValue;
    } else {
      setSearchQuery({});
      userSelectedFilter["search"] = "";
    }
  }, [inputValue, setSearchQuery]);

  const findSuboptions = (subObjArr, parentKey, subOptionsFromParam) => {
    return subObjArr.map((sub) => ({
      type: sub.name,
      colour: sub.color_code,
      id: sub.id,
      checked: subOptionsFromParam
        ? findCheckedStatusFromUrlParams(subOptionsFromParam, sub.id, parentKey)
        : false,
      parent: parentKey,
    }));
  };

  const findFilterOptions = (filterObj, urlParams) => {
    const keyCollection = Object.keys(urlParams);

    const isOpenTicketsChecked =
      urlParams["open tickets"] !== undefined
        ? urlParams["open tickets"]
        : true;
    const isClosedTicketsChecked =
      urlParams["closed tickets"] !== undefined
        ? urlParams["closed tickets"]
        : false;

    const isClosedDateSelected = checkIfClosedDateIsChecked(
      urlParams["closedDate"]
    );

    const selectedFirstResponseSLA = urlParams["first_response_sla"];

    return Object.keys(filterObj)
      .map((item) => {
        return {
          type: item,
          checked: keyCollection.includes(item),
          isHighlighted: false,
          suboptions: findSuboptions(filterObj[item], item, urlParams[item]),
          showsuboptions: false,
          disabled: false,
        };
      })
      .concat([
        {
          type: "first_response_sla",
          checked: selectedFirstResponseSLA != "",
          isHighlighted: false,
          suboptions: [
            {
              type: "Achieved",
              id: "Achieved",
              checked: selectedFirstResponseSLA == "Achieved",
              parent: "first_response_sla",
              showsuboptions: false,
            },
            {
              type: "Breached",
              id: "Breached",
              checked: selectedFirstResponseSLA == "Breached",
              parent: "first_response_sla",
              showsuboptions: false,
            },
          ],
          showsuboptions: false,
        },
        {
          type: "date",
          checked: urlParams["date"]
            ? checkIfDateIsChecked(urlParams["date"])
            : false,
          isHighlighted: false,
          suboptions: [
            {
              type: "Date created",
              id: "Date created",
              checked: false,
              parent: "date",
              suboptions: [
                {
                  type: "From",
                  id: "From",
                  checked: false,
                  parent: "Date created",
                },
                {
                  type: "To",
                  id: "To",
                  checked: false,
                  parent: "Date due",
                },
              ],
              showsuboptions: false,
              disabled: false,
            },
            {
              type: "Date due",
              id: "Date due",
              checked: false,
              parent: "date",
              suboptions: [
                {
                  type: "From",
                  id: "From",
                  checked: false,
                  parent: "Date created",
                  showsuboptions: false,
                },
                {
                  type: "To",
                  id: "To",
                  checked: false,
                  parent: "Date due",
                  showsuboptions: false,
                },
              ],
              showsuboptions: false,
              disabled: false,
            },
          ],
          showsuboptions: false,
        },
        {
          type: "closedDate",
          checked: isClosedDateSelected,
          isHighlighted: false,
          suboptions: [
            {
              type: "From",
              id: "From",
              checked: false,
              parent: "closedDate",
              showsuboptions: false,
            },
            {
              type: "To",
              id: "To",
              checked: false,
              parent: "closedDate",
              showsuboptions: false,
            },
          ],
          showsuboptions: false,
          disabled: false,
        },
        {
          type: "closed tickets",
          checked: isClosedTicketsChecked,
          isHighlighted: false,
          suboptions: [],
          showsuboptions: false,
          disabled: false,
        },
        {
          type: "open tickets",
          checked: isOpenTicketsChecked,
          isHighlighted: false,
          suboptions: [],
          showsuboptions: false,
          disabled: false,
        },
      ]);
  };

  const checkIfClosedDateIsChecked = (closedDate) =>
    closedDate?.From?.length > 0 || closedDate?.To?.length > 0;

  const checkIfDateIsChecked = (date) => {
    const isDateCreatedChecked =
      date["Date created"]?.From.length > 0 ||
      date["Date created"]?.To.length > 0;

    const isDateDueChecked =
      date["Date due"].From.length > 0 || date["Date due"]?.To.length > 0;

    return isDateCreatedChecked || isDateDueChecked;
  };

  const fetchDataForTicketsTable = async (customUrl) => {
    const fetchUrl = customUrl || url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setIsLoading(true);
    const data = await customFetch(fetchUrl, "GET");
    const results = data.results;
    const ticketsCount = data.count;
    if (customUrl) setRowData(results);
    else setRowData([...rowData, ...results]);
    setCountTickets(ticketsCount);
    if (data?.next != null) setUrl(data.next);
    else setUrl(fetchUrl);
    setIsLoading(false);
  };

  const fetchDataForGroupTicketsTable = async (fetchUrl, groupKey) => {
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setIsLoading(true);

    const data = await customFetch(fetchUrl, "GET");

    setGroupByRowData(data);

    const oldDataKeys = Object.keys(groupByRowData);
    const newdataKey = groupKey;

    let newRowData = { ...groupByRowData };

    if (oldDataKeys.includes(newdataKey)) {
      newRowData[newdataKey].results = [
        ...newRowData[newdataKey].results,
        ...data[newdataKey].results,
      ];
      newRowData[newdataKey].next = data[newdataKey].next;
      newRowData[newdataKey].previous = data[newdataKey].previous;

      setGroupByRowData(newRowData);
    }

    setIsLoading(false);
  };

  const handleToggleGroupBy = (option) => {
    const updatedOption = groupBy.includes(option.id) ? [] : [option.id];
    setGroupBy(updatedOption);
    setGroupByUrl(`${baseURL}v2/ticket/?limit=15&grouped_by=${option.id}`);
    setGroupByRowData([]);
  };

  const fetchFiltersData = async () => {
    const data = await customFetch(`${baseURL}v2/ticket/filter_config`, "GET");

    setAllFilters(data);
    sessionStorage.setItem("allFilters", JSON.stringify(data));

    setFilterOptions(findFilterOptions(data, urlUserSelectedParams));

    setUserSelectedFilter({
      ...userSelectedFilter,
      ...urlUserSelectedParams,
    });
  };

  const handleChildClick = (
    e,
    parentFilterType,
    childFilterId,
    parentFilterIndex,
    childFilterIndex,
    sub,
    childFilterType
  ) => {
    e.stopPropagation();

    updateUserSelectedFilter(
      e,
      parentFilterType,
      childFilterId,
      parentFilterIndex,
      childFilterIndex,
      sub,
      childFilterType,
      handleChildFilterUnchecked,
      userSelectedFilter,
      filterOptions,
      setFilterOptions,
      setUserSelectedFilter
    );
  };

  const isFilterNonEmpty = (filter) => filter.length > 0;

  const isDateFilterNonEmpty = (dateFilter) => {
    const { "Date created": dateCreated, "Date due": dateDue } = dateFilter;
    return [
      dateCreated["From"],
      dateCreated["To"],
      dateDue["From"],
      dateDue["To"],
    ].some(isFilterNonEmpty);
  };

  const isClosedDateFilterNonEmpty = (closedDateFilter) => {
    const { From, To } = closedDateFilter;
    return [From, To].some(isFilterNonEmpty);
  };

  const hasVisibleFilter = (key, value) => {
    switch (key) {
      case "date":
        return isDateFilterNonEmpty(value);
      case "closedDate":
        return isClosedDateFilterNonEmpty(value);
      case "closed tickets":
      case "open tickets":
        return Boolean(value);
      case "search":
        return Boolean(false);
      default:
        return isFilterNonEmpty(value);
    }
  };

  const checkClearAllVisibility = () => {
    const updatedUserSelectedFilter = { ...userSelectedFilter };

    const anyFilterVisible = Object.keys(updatedUserSelectedFilter).some(
      (key) => hasVisibleFilter(key, updatedUserSelectedFilter[key])
    );

    return anyFilterVisible ? "visible" : "hidden";
  };

  const removeCheckFromAllSuboptions = (suboptions, filterType) =>
    suboptions.map((subotpn) => ({
      ...subotpn,
      checked: false,
    }));

  const fetchExportsData = async () => {
    setDisplayDownloadProgress("inline-block");
    const response = await fetch(
      `${baseURL}v2/ticket/export/?${parameterString.slice(1)}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Token ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const blob = await response.blob();

    const exportsResponseUrl = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");

    link.href = exportsResponseUrl;
    const currentTime = new Date().toISOString().slice(0, 19).replace(/T/, "-");
    const filename = `Guardian-Export-${currentTime}.csv`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);

    setDisplayDownloadProgress("none");
  };

  const handleExport = (e) => {
    fetchExportsData();
  };

  const filterFunction = (e) => {
    let input, filter, a;
    input = e.target;

    filter = input.value.toUpperCase();

    let div = document.getElementsByClassName(
      "filter_dropdown_child_options"
    )[0];

    a = div.getElementsByClassName("sub_filter_checkbox");

    for (const element of a) {
      let txtValue = element.textContent || element.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        element.style.display = "";
      } else {
        element.style.display = "none";
      }
    }
  };

  const handleOnDateHover = (e, subType) => {
    const dateSubTypeIndex = subType === "Date created" ? 0 : 1;
    const otherDateSubTypeIndex = dateSubTypeIndex === 0 ? 1 : 0;
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.type === "date") {
          let tempSubOptions = [...filterOption.suboptions];
          tempSubOptions[dateSubTypeIndex].showsuboptions = true;
          tempSubOptions[otherDateSubTypeIndex].showsuboptions = false;

          return {
            ...filterOption,
            suboptions: tempSubOptions,
          };
        } else return filterOption;
      })
    );
  };

  const handleMouseOver = (e, index, filter) => {
    if (filter.type !== "closed tickets" && filter.type !== "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    } else {
      handleHideAllSuboptions(filterOptions, handleSetFilterOptions);
    }
  };

  const handleSetFilterOptions = (updatedFilterOptions) => {
    setFilterOptions(updatedFilterOptions);
  };

  const navigate = useNavigate();

  const handleOpenTicket = (team, ticket_id) => {
    navigate(`/tickets/${team}/${ticket_id}`);
  };

  storedFilters(userSelectedFilter);

  useEffect(() => {
    if (fetchFiltersDataCount.current === 0) {
      fetchFiltersData();
      fetchFiltersDataCount.current += 1;
    }
  }, []);

  const hasFilters = Object.keys(userSelectedFilter).some(
    (key) => userSelectedFilter[key]?.length > 0
  );

  useEffect(() => {
    const updatedParams = generateParamString(userSelectedFilter, allFilters);
    setParameterString(updatedParams);

    setFilterOptions(findFilterOptions(allFilters, urlUserSelectedParams));
    updateUrlWithFilters(userSelectedFilter);

    if (groupBy?.length > 0) {
      if (!hasFilters) {
        fetchDataForGroupTicketsTable(groupByUrl);
      } else if (updatedParams) {
        const updatedUrl = `${groupByUrl}${updatedParams}`;
        fetchDataForGroupTicketsTable(updatedUrl);
      }
    }
  }, [
    userSelectedFilter,
    JSON.stringify(urlUserSelectedParams).length,
    groupBy,
  ]);

  useEffect(() => {
    const cleanup = handleClickOutsideFilters("filters_dropdown");

    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="ticketSummaryScreen">
      <div className="div">
        <div className="div-13">
          <Header
            parameters={parameterString}
            heading={getOverallTicketType(userSelectedFilter, allFilters)}
            handleClearSearch={handleClearSearch}
            handleInputChange={handleInputChange}
            updateHandleInputChange={updateHandleInputChange}
            inputValue={inputValue}
            buttonDisplay="none"
            buttonTitle="Add Ticket"
            showFiltersDropdown={() => showFiltersDropdown()}
            onMouseOver={handleMouseOver}
            handleParentFilterUnchecked={handleParentFilterUnchecked}
            filterOptions={filterOptions}
            getSubFilterClassName={getSubFilterClassName}
            onKeyUp={filterFunction}
            FilterSubOptions={FilterSubOptions}
            handleOnDateHover={handleOnDateHover}
            handleChildClick={handleChildClick}
            FindChildFilterSubElements={FindChildFilterSubElements}
            setFilterOptions={setFilterOptions}
            userSelectedFilter={userSelectedFilter}
            setUserSelectedFilter={setUserSelectedFilter}
            displaySelectedFilters={displaySelectedFilters(
              userSelectedFilter,
              capitalizeFirstLetter,
              allFilters,
              setUserSelectedFilter,
              filterOptions,
              setFilterOptions
            )}
            checkClearAllVisibility={`${checkClearAllVisibility()}`}
            clearAllSelectedFilters={() => {
              clearAllSelectedFilters(
                setUserSelectedFilter,
                setFilterOptions,
                filterOptions,
                removeCheckFromAllSuboptions,
                setInputValue
              );
              setGroupByRowData([]);
            }}
            style={{ display: `${displayDownloadProgress}` }}
            handleExport={handleExport}
            exportButtonTitle="Export"
          />
          <GroupByFilter
            groupBy={groupBy}
            handleToggleGroupBy={handleToggleGroupBy}
          />
          {groupBy.length > 0 ? (
            <GroupByTable
              url={groupByUrl}
              dataIsLoading={isLoading}
              parameters={parameterString}
              fetchData={fetchDataForGroupTicketsTable}
              tableData={groupByRowData}
              tableHeadCells={ticketHeadCells}
              tableKeys={ticketkeys}
              display="none"
              clearAllSelectedFilters={() => {
                clearAllSelectedFilters(
                  setUserSelectedFilter,
                  setFilterOptions,
                  filterOptions,
                  removeCheckFromAllSuboptions,
                  setInputValue
                );
                setGroupByRowData([]);
              }}
              handleOpenTicket={handleOpenTicket}
              orderedColumn={selectedColumn}
              handleSetSelectedColumn={handleSetSelectedColumn}
            />
          ) : (
            <TicketsTable
              parameters={parameterString}
              orderedColumn={selectedColumn}
              clearAllSelectedFilters={() => {
                clearAllSelectedFilters(
                  setUserSelectedFilter,
                  setFilterOptions,
                  filterOptions,
                  removeCheckFromAllSuboptions,
                  setInputValue
                );
                setGroupByRowData([]);
              }}
              urlUserSelectedParams={urlUserSelectedParams}
              handleSetSelectedColumn={handleSetSelectedColumn}
              rowData={rowData}
              isLoading={isLoading}
              countTickets={countTickets}
              setRowData={setRowData}
              url={url}
              fetchDataForTicketsTable={fetchDataForTicketsTable}
              handleOpenTicket={handleOpenTicket}
              tableHeadCells={ticketHeadCells}
              tableKeys={ticketkeys}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TicketSummaryScreen = () => {
  return <TicketSummaryScreenComponent />;
};

export default TicketSummaryScreen;
