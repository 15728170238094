const SupportLevelTaskStatus = {
  L1_PICKUP: "L1 pickup",
  L2_PICKUP: "L2 pickup",
  L3_PICKUP: "L3 pickup",
};

const validEscalations = {
  [SupportLevelTaskStatus.L1_PICKUP]: [
    SupportLevelTaskStatus.L2_PICKUP,
    SupportLevelTaskStatus.L3_PICKUP,
  ],
  [SupportLevelTaskStatus.L2_PICKUP]: [SupportLevelTaskStatus.L3_PICKUP],
  [SupportLevelTaskStatus.L3_PICKUP]: [],
};

export const isValidEscalation = (statusChangedFrom, statusChangedTo) => {
  return (
    statusChangedFrom in validEscalations &&
    validEscalations[statusChangedFrom].includes(statusChangedTo)
  );
};

export const getStatusLevel = (status) => status?.toLowerCase().split(" ")[0];

export const escalationNotesMap = {
  l1: { next: ["l1", "l2"], note: "L1 escalation notes" },
  l2: { next: ["l3"], note: "L2 escalation notes" },
};

export const requiredEscalationNote = (value, updateInputBox) => {
  const currentStatusLevel = getStatusLevel(value);
  const newStatusLevel = getStatusLevel(updateInputBox);

  return escalationNotesMap[currentStatusLevel]?.next.includes(newStatusLevel)
    ? escalationNotesMap[currentStatusLevel]?.note
    : "";
};

export const validation = (
  ticketData,
  validateField,
  requiredEscalationNote,
  selectedStatus,
  statusFrom = ""
) => {
  if (validateField == "subTeam") {
    const isSubTeamEnforced =
      ticketData?.team_configs?.enforce_sub_team_for_level?.includes(
        getStatusLevel(selectedStatus)
      );
    const isSubTeamPresent = ticketData.sub_team == null;

    return isSubTeamEnforced && isSubTeamPresent;
  }

  if (validateField == "escalationNotes") {
    const isEscalationNotesEnforced =
      ticketData?.team_configs.escalation_notes_required;
    const isEscalationNotesPresent =
      [null, "", "<p><br></p>"].includes(
        ticketData.escalation_notes[requiredEscalationNote]
      ) && requiredEscalationNote != "";


    return (
      isEscalationNotesEnforced &&
      isEscalationNotesPresent &&
      isValidEscalation(statusFrom, selectedStatus)
    );
  }

  if (validateField == "resolutionNotes") {
    const isStatusClosed = selectedStatus === "Complete";
    const isResolutionNotesEnforced =
      ticketData?.team_configs.resolution_notes_required;
    const isResolutionNotesPresent = ticketData.resolution_notes == null;

    return (
      isStatusClosed && isResolutionNotesEnforced && isResolutionNotesPresent
    );
  }
};
