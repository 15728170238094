import { customFetch } from "./requestServices";

export const getFirstResponseSLA = async (paramString, url) => {
  const response = await customFetch(
    `${url}v2/dashboard/first_response_sla/${paramString}`,
    "GET"
  );

  return response;
};

export const getOpenTickets = async (paramString, url) => {
  const response = await customFetch(
    `${url}v2/dashboard/ticket_sla_summary/${paramString}`,
    "GET"
  );
  return response;
};

export const getClosedTickets = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/completed_tickets_sla/${paramString.replace(
      "&",
      "?"
    )}`,
    "GET"
  );

  return response;
};

export const getFiltersData = async (url) => {
  const response = await customFetch(`${url}v2/ticket/filter_config`, "GET");

  return response;
};

export const getStatusBarChartData = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/status_duration/?ticket_type=closed&${paramString.slice(
      1
    )}`,
    "GET"
  );
  return response;
};

export const getSquadBarChartData = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/squad_duration/?ticket_type=closed&${paramString.slice(
      1
    )}`,
    "GET"
  );

  return response;
};

export const getClosedBarChartData = async (parameterString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/tickets_closed_by_squad/${parameterString.replace(
      "&",
      "?"
    )}`,
    "GET"
  );
  return response;
};

export const getDashboardMetricData = async (url) => {
  const response = await customFetch(`${url}&ticket_type=closed`, "GET");
  return response;
};

export const getDashboardMetricExportData = async (parameterString, url) => {
  const response = await fetch(
    `${url}v1/dashboard_metric/export/?ticket_type=closed&${parameterString.slice(
      1
    )}`,
    {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Token ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return response;
};

export const getSpaceData = async (url) => {
  const response = await customFetch(`${url}v1/clickup/spaces/`, "GET");
  return response;
};

export const getFolderData = async (spaceId, url) => {
  const response = await customFetch(
    `${url}v1/clickup/spaces/${spaceId}/folders/`,
    "GET"
  );
  return response;
};

export const getListData = async (folderId, url) => {
  const response = await customFetch(
    `${url}v1/clickup/folders/${folderId}/lists/`,
    "GET"
  );
  return response;
};

export const getListWithoutFolderData = async (spaceId, url) => {
  const response = await customFetch(
    `${url}v1/clickup/spaces/${spaceId}/lists_without_folder/`,
    "GET"
  );

  return response;
};

export const getSubTeamList = async (teamId, url) => {
  const response = await customFetch(`${url}?team_id=${teamId}`, "GET");

  return response;
};

export const getTeamStatusList = async (teamId, url) => {
  const response = await customFetch(`${url}/${teamId}/get-status/`, "GET");

  return response;
};

export const returnWebSocketUrl = (baseURL,ticketId) => {
  const getToken = localStorage.getItem("access_token");
  const getURI = baseURL.split("://")[1];
  return `wss://${getURI}ws/ticket/${ticketId}/${getToken}/`;
};
