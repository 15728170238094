import React from "react";
import { Tooltip, TextField, Stack, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Divider from "@mui/material/Divider";
import CustomTimePicker from "../CustomTimePicker";
import { BasicDetailsCustomisedChips } from "../EditTeamPage";
import { tooltip_message } from "../../utils/constants";
import {
  getReportingConfigurationMessage,
  periodFrequency,
} from "../EditTeamPage/component_functions";

const ReportingConfiguration = ({
  edit_permission,
  dailySummaryChannel,
  setDailySummaryChannel,
  dataForPutApi,
  setDataForPutApi,
  setSaveButtonStatus,
  onAcceptTimePicker,
  notificationFrequency,
  handleDeleteNotificationFreq,
  reportingFrequency,
  handleToggleReportingFrequency,
}) => {
  return (
    <Grid sx={{ color: "#3C3E49", fontSize: "14px", marginBottom: "1rem" }}>
      <h3 className="sectionHeading">Reporting</h3>
      <Divider />
      <Grid sx={{ paddingY: "0.8rem" }}>
        <Grid
          sx={{ color: "#3C3E49", fontSize: "14px", alignItems: "center" }}
          container
        >
          <Grid size={10} sx={{ lineHeight: "3rem", display: "flex" }}>
            Reporting channel
            <Tooltip title={tooltip_message.reporting_channel}>
              <InfoOutlinedIcon
                sx={{ margin: "0.9rem 0rem 0rem 0.1rem", fontSize: "14px" }}
              />
            </Tooltip>
          </Grid>
          <Grid size={2} sx={{ textAlign: "end" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={!edit_permission()}
              value={dailySummaryChannel}
              onChange={(e) => {
                setDailySummaryChannel(e.target.value);
                setDataForPutApi({
                  ...dataForPutApi,
                  daily_summary_slack_channel_id: e.target.value,
                });
                setSaveButtonStatus(true);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  maxHeight: "36px !important",
                  fontSize: "14px",
                  color: "#3c3e49",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            paddingY: "1rem",
            alignItems: "center",
          }}
        >
          <Grid size={9} justifySelf="flex-end" sx={{ lineHeight: "3rem" }}>
            Daily summary notification times
            <Tooltip title={tooltip_message.daily_summary_frequency}>
              <InfoOutlinedIcon
                sx={{ margin: "0rem 0rem 0rem 0.1rem", fontSize: "14px" }}
              />
            </Tooltip>
          </Grid>
          <Grid size={3} sx={{ textAlign: "end" }}>
            <CustomTimePicker
              disabled={!edit_permission()}
              views={["hours", "minutes"]}
              sx={{
                width: "11rem",
                "& .MuiOutlinedInput-root": {
                  height: "36px",
                  fontSize: "12px",
                },
                "& .MuiButtonBase-root": {
                  fontSize: "12px",
                },
              }}
              placeholder={"Select time"}
              onAccept={onAcceptTimePicker}
            />
            <Grid
              sx={{
                height: notificationFrequency?.length > 0 ? "2rem" : "0",
                justifySelf: "flex-end",
                paddingTop: notificationFrequency?.length > 0 ? "1rem" : "0",
                display: "grid",
                justifyContent: "flex-end",
              }}
            >
              <BasicDetailsCustomisedChips
                customField={notificationFrequency}
                handleDelete={handleDeleteNotificationFreq}
                edit_permission={edit_permission}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ color: "#3C3E49", fontSize: "14px", alignItems: "center" }}
        >
          <Grid size={8} sx={{ lineHeight: "3.5rem" }}>
            SLA Report
            <p
              style={{
                lineHeight: "1",
                fontSize: "0.7rem",
                color: "rgba(0, 0, 0, 0.54)",
                textWrap: "wrap",
              }}
            >
              {getReportingConfigurationMessage(reportingFrequency)}
            </p>
          </Grid>
          <Grid size={4} sx={{ display: "grid", justifyContent: "flex-end" }}>
            <Stack direction="row" spacing={1}>
              {periodFrequency.map((frequency) => (
                <Chip
                  key={frequency}
                  label={frequency.charAt(0).toUpperCase() + frequency.slice(1)}
                  onClick={() => handleToggleReportingFrequency(frequency)}
                  sx={{
                    backgroundColor: reportingFrequency?.includes(frequency)
                      ? "#7C4DFF"
                      : "#F3EBFF",
                    color: reportingFrequency?.includes(frequency)
                      ? "#FFF"
                      : "#3C3E49",
                    borderColor: reportingFrequency?.includes(frequency)
                      ? "transparent"
                      : "#dfcdfa",
                    fontSize: "12px",
                    cursor: edit_permission() ? "pointer" : "not-allowed",
                    "&:hover": {
                      backgroundColor: reportingFrequency?.includes(frequency)
                        ? "#a081f7"
                        : "#E0D7F5",
                    },
                  }}
                  disabled={!edit_permission()}
                  variant={
                    reportingFrequency?.includes(frequency)
                      ? "default"
                      : "outlined"
                  }
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportingConfiguration;
