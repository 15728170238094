import React, { useState } from "react";
import { MenuItem, Typography } from "@mui/material";
import UserIcon from "../UserIcon";
import { checkPriority } from "../../utils/TicketsTableFunctions";
import { tagChips } from "../../utils/TicketDetailsCommonFunction";
import {
  getStatusLevel,
  isValidEscalation,
  validation,
  escalationNotesMap,
  requiredEscalationNote,
} from "../../utils/EscalationValidations";
import { useTicketDetail } from "../../context/TicketDetailContext";
import TicketSelectField from "./textField";

const UpdateTicketConfig = ({
  type,
  value,
  listData,
  updateFunction,
  notes,
  handleNotesChange,
}) => {
  const { ticketData, updateTicketDetails, updateSubTeam } = useTicketDetail();

  const [open, setOpen] = useState(false);
  const [updateInputBox, setUpdateInputBox] = useState(value);
  const [subTeamUpdate, setSubTeamUpdate] = useState(
    ticketData?.sub_team || ""
  );
  const [textEscalation, setTextEscalation] = useState({
    "L1 escalation notes": false,
    "L2 escalation notes": false,
    "Resolution notes": false,
  });

  if (!value?.length && !["priority", "sub_team", "status"].includes(type)) {
    return <Typography sx={{ color: "#9b9fb1" }}>Empty</Typography>;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUpdateInputBox(value);
  };

  const handleModalOpen = (newStatus) => {
    const isSubTeamInvalid = validation(
      ticketData,
      "subTeam",
      requiredEscalationNote(value, newStatus),
      newStatus
    );
    const isEscalationNotesInvalid = validation(
      ticketData,
      "escalationNotes",
      requiredEscalationNote(value, newStatus),
      newStatus,
      value
    );
    const isResolutionNotesInvalid = validation(
      ticketData,
      "resolutionNotes",
      requiredEscalationNote(value, newStatus),
      newStatus
    );

    if (
      isSubTeamInvalid ||
      isEscalationNotesInvalid ||
      isResolutionNotesInvalid
    ) {
      handleOpen();
    } else {
      handleProceed(newStatus);
    }
  };

  const updateStatusInputBox = (newStatus) => {
    setUpdateInputBox(newStatus);
    handleModalOpen(newStatus);
  };

  const handleProceed = async (updatedStatus) => {
    if (value == updatedStatus) return;

    const isEscalation = isValidEscalation(value, updatedStatus);
    const prevStatusLevel = getStatusLevel(value);
    const statusLevel = getStatusLevel(updatedStatus);

    const requiredEscalationNote = escalationNotesMap[
      prevStatusLevel
    ]?.next.includes(statusLevel)
      ? escalationNotesMap[prevStatusLevel]?.note
      : "";

    const isSubTeamEnforced =
      ticketData?.team_configs?.enforce_sub_team_for_level.includes(
        statusLevel
      );
    const isSubTeamProvided = ticketData.sub_team != null;

    const isEscalationNotesProvided =
      ticketData?.team_configs?.escalation_notes_required &&
      ![null, "", "<p><br></p>"].includes(
        ticketData.escalation_notes[requiredEscalationNote]
      );

    const isResolutionNotesProvided =
      ticketData?.team_configs?.resolution_notes &&
      updatedStatus == "Complete" &&
      ticketData.resolution_notes != null;

    if (
      !isEscalation ||
      (isEscalationNotesProvided && (!isSubTeamEnforced || isSubTeamProvided))
    ) {
      updateTicketDetails("status", updatedStatus?.toLowerCase());
    } else if (
      isSubTeamEnforced &&
      isSubTeamProvided &&
      isEscalationNotesProvided
    ) {
      updateTicketDetails("status", updatedStatus?.toLowerCase());
    } else if (isResolutionNotesProvided) {
      updateTicketDetails("status", updatedStatus?.toLowerCase());
    } else {
      try {
        let subTeamResponse = "";
        let notesResponse = "";

        if (subTeamUpdate?.id) {
          subTeamResponse = await updateSubTeam(subTeamUpdate);
          setOpen(false);
        }

        let keyWithTrueValue;
        for (const key in textEscalation) {
          if (textEscalation[key] === true) {
            keyWithTrueValue = key;
            break;
          }
        }

        if (notes[keyWithTrueValue] != null) {
          notesResponse = await updateTicketDetails(
            keyWithTrueValue,
            notes[keyWithTrueValue]
          );
          setOpen(false);
        }

        if (subTeamResponse === "success" || notesResponse === "success") {
          updateTicketDetails("status", updatedStatus?.toLowerCase());
          setOpen(false);
        }
      } finally {
        setOpen(false);
      }
    }
  };

  switch (type) {
    case "status":
      return (
        <TicketSelectField
          type={type}
          value={value}
          optionsMapper={(item) => (
            <MenuItem
              key={item.id}
              value={item.name}
              sx={{ padding: "0.8rem" }}
              onClick={() => updateStatusInputBox(item.name)}
            >
              <Typography>{item.name}</Typography>
            </MenuItem>
          )}
          listData={listData}
          updateFunction={updateFunction}
          isStatus={true}
          open={open}
          notes={notes}
          updateInputBox={updateInputBox}
          subTeamUpdate={subTeamUpdate}
          setSubTeamUpdate={setSubTeamUpdate}
          textEscalation={textEscalation}
          setTextEscalation={setTextEscalation}
          handleNotesChange={handleNotesChange}
          handleClose={handleClose}
          handleProceed={handleProceed}
        />
      );

    case "assignee":
      return listData.length > 0 ? (
        <UserIcon
          image_url={listData[0].url}
          username={listData[0].username}
          colorCode={listData[0].color_code}
        />
      ) : (
        <Typography sx={{ color: "#9b9fb1" }}>Empty</Typography>
      );

    case "text":
      return <Typography>{value}</Typography>;

    case "priority":
      return (
        <TicketSelectField
          type={type}
          value={value}
          optionsMapper={(item) => (
            <MenuItem
              key={item.id}
              value={item.name}
              sx={{ padding: "0.8rem" }}
            >
              {checkPriority(item.name, true)}
            </MenuItem>
          )}
          listData={listData}
          updateFunction={updateFunction}
        />
      );

    case "tag":
      return tagChips(value);

    case "sub_team":
      return (
        <TicketSelectField
          type={type}
          value={value}
          optionsMapper={(item) => (
            <MenuItem
              key={item.id}
              value={item.name}
              sx={{ padding: "0.5rem" }}
            >
              <Typography>{item.name}</Typography>
            </MenuItem>
          )}
          listData={listData}
          updateFunction={updateFunction}
        />
      );

    default:
      return <Typography sx={{ color: "#9b9fb1" }}>Empty</Typography>;
  }
};

export default UpdateTicketConfig;
