import { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import TextEditor from "../TextEditor";
import { TextField, MenuItem } from "@mui/material";
import "./style.css";
import { useTicketDetail } from "../../context/TicketDetailContext";
import { validation } from "../../utils/EscalationValidations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "0.5rem",
  boxShadow: 20,
};

const getEscalationInfo = (
  isResolutionNotesMissing,
  isSubteamMissing,
  isEscalationNotesMissing,
  requiredEscalationNote,
  mode
) => {
  const missingFields = {
    subTeam: isSubteamMissing,
    resolutionNotes: isResolutionNotesMissing,
    escalationNotes: isEscalationNotesMissing,
  };

  const messages = {
    heading: {
      both: "Sub team and escalation notes required",
      subTeam: "Sub team required",
      resolutionNotes: "Resolution notes required",
      escalationNotes: "Escalation notes required",
    },
    message: {
      both: "You are escalating this ticket to a higher support level. Please provide escalation notes and sub-team before proceeding. This ensures a smooth handoff and clear context for the next team.",
      subTeam:
        "Please provide the sub-team before proceeding to ensure a smooth handoff.",
      resolutionNotes:
        "This ticket is being marked as complete. Please provide resolution notes.",
      escalationNotes:
        "You are escalating this ticket to a higher support level. Please provide escalation notes to ensure a smooth handoff and clear context for the next team.",
    },
    label: {
      both: requiredEscalationNote,
      resolutionNotes: "Resolution notes",
      escalationNotes: requiredEscalationNote,
    },
  };

  if (missingFields.subTeam && missingFields.escalationNotes) {
    return messages[mode]?.both || "";
  }

  for (const [key, isMissing] of Object.entries(missingFields)) {
    if (isMissing) return messages[mode]?.[key] || "";
  }

  return "";
};

const TicketEscalatePopup = ({
  prevStatus,
  status,
  notes,
  handleNotesChange,
  openModal,
  requiredEscalationNote,
  setTextEscalation,
  textEscalation,
  setSubTeamUpdate,
  subTeamUpdate,
  handleClose,
  handleProceed,
}) => {
  const { ticketData, subTeams } = useTicketDetail();

  const [isEditing, setIsEditing] = useState(false);
  const [currentKey, setCurrentKey] = useState("");
  const [currentData, setCurrentData] = useState("");
  const editorRef = useRef(null);

  const heading = getEscalationInfo(
    validation(ticketData, "resolutionNotes", requiredEscalationNote, status),
    validation(ticketData, "subTeam", requiredEscalationNote, status),
    validation(
      ticketData,
      "escalationNotes",
      requiredEscalationNote,
      status,
      prevStatus
    ),
    requiredEscalationNote,
    "heading"
  );

  const message = getEscalationInfo(
    validation(ticketData, "resolutionNotes", requiredEscalationNote, status),
    validation(ticketData, "subTeam", requiredEscalationNote, status),
    validation(
      ticketData,
      "escalationNotes",
      requiredEscalationNote,
      status,
      prevStatus
    ),
    requiredEscalationNote,
    "message"
  );

  const label = getEscalationInfo(
    validation(ticketData, "resolutionNotes", requiredEscalationNote, status),
    validation(ticketData, "subTeam", requiredEscalationNote, status),
    validation(
      ticketData,
      "escalationNotes",
      requiredEscalationNote,
      status,
      prevStatus
    ),
    requiredEscalationNote,
    "label"
  );

  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      handleNotesChange(currentKey, currentData);
      setTextEscalation({ ...textEscalation, [currentKey]: true });
      setIsEditing(false);
      // setCurrentKey("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentData,currentKey]);

  return (
    <div>
      <Modal id="escalationModal" open={openModal}>
        <Box sx={style}>
          <div className="modal-title">
            <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
              {heading}
            </Typography>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>

          <Divider />

          <div className="modal-description">
            <Typography sx={{ color: "#676B7E" }}>{message}</Typography>
          </div>

          {validation(
            ticketData,
            "subTeam",
            requiredEscalationNote,
            status
          ) && (
            <div className="sub-team">
              <Typography sx={{ fontWeight: 500, mb: "0.4rem" }}>
                Sub team
              </Typography>
              <TextField
                select
                fullWidth
                value={subTeamUpdate.name || ""}
                onChange={(e) => {
                  setSubTeamUpdate(
                    subTeams.find((item) => item.name === e.target.value)
                  );
                }}
                sx={{
                  fontSize: "14px",
                  borderRadius: "0.5rem",
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "36px !important",
                    fontSize: "14px",
                  },
                }}
              >
                {subTeams.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}

          {label && (
            <div className="text-editor">
              <Typography sx={{ fontWeight: 500, mb: "0.4rem" }}>
                {label}
              </Typography>
              <div
                className={`modal-content-editor ${
                  currentKey == label ? "" : "not-editing"
                }`}
                onClick={() => {
                  setCurrentKey(label);
                }}
              >
                <div ref={editorRef} tabIndex={0}>
                  <TextEditor
                    height={"10vh"}
                    initialValue={notes[label]}
                    onChange={(content) => {
                      setCurrentKey(label);
                      setCurrentData(content);
                    }}
                    setIsEditing={setIsEditing}
                    readOnly={currentKey != label}
                  />
                </div>
              </div>
            </div>
          )}

          <Divider />

          <div className="modal-button">
            <Button
              variant="contained"
              sx={{
                background: " #F3EBFF",
                color: "#000",
                borderRadius: "0.5rem",
                textTransform: "none",
                "&:hover": {
                  background: " #F3EBFF",
                },
                "&.Mui-disabled": {
                  background: "#F3EBFF",
                  color: "#929292",
                  opacity: 1,
                },
              }}
              onClick={() => handleProceed(status)}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TicketEscalatePopup;
