import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./style.css";
import { MaterialTable } from "../MaterialTable";
import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";
import NoRecords from "../../assets/empty state - no search result.svg";
import Button from "@mui/material/Button";
import { CircularLoader } from "../CircularLoader";

const GroupByTable = ({
  url,
  dataIsLoading,
  parameters,
  fetchData,
  tableData,
  tableHeadCells,
  tableKeys,
  display,
  clearAllSelectedFilters,
  handleOpenTicket,
  orderedColumn,
  handleSetSelectedColumn,
}) => {
  const tableProps = {
    url,
    dataIsLoading,
    parameters,
    fetchData,
    tableHeadCells,
    tableKeys,
    display,
    handleOpenTicket,
    orderedColumn,
    handleSetSelectedColumn,
  };

  return (
    <div className="groupby_table">
      {tableData && Object.keys(tableData).length > 0 ? (
        Object.keys(tableData).map((key) => (
          <Accordion
            key={key}
            defaultExpanded
            sx={{
              boxShadow: "none",
              minHeight: "2rem",
              padding: 0,
              marginBottom: "2rem !important",
              "& .MuiAccordionSummary-root": {
                padding: 0,
              },
            }}
          >
            <AccordionSummary
              aria-controls={`panel${key}-content`}
              id={`panel${key}-header`}
              sx={{
                minHeight: 0,
                "& .MuiAccordionSummary-content": {
                  alignItems: "center",
                },
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  marginRight: "0.5rem",
                  fontSize: "1.8rem",
                  color: "text.secondary",
                }}
              />
              <Typography component="div" sx={{ fontWeight: 500 }}>
                {capitalizeFirstLetter(key)}
                <span
                  style={{
                    color: "#83889E",
                    marginLeft: "1rem",
                  }}
                >
                  {tableData[key]?.count} task(s)
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                maxHeight: "45vh",
                overflow: "auto",
                scrollbarWidth: "none",
                padding: "0 1rem",
              }}
            >
              <MaterialTable
                {...tableProps}
                tableData={tableData[key]?.results || []}
                groupKey={key}
                next_url={tableData[key]?.next}
              />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div
          className="no_records_found"
          style={{
            marginTop: "5rem",
          }}
        >
          {dataIsLoading ? (
            <CircularLoader />
          ) : (
            <>
              <img
                src={NoRecords}
                alt="No Records"
                style={{ width: "5rem", height: "5rem" }}
              />
              <div
                style={{
                  color: "var(--Base-Blue-grey-10, #3C3E49)",
                  fontFamily: "Rubik",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "2rem",
                }}
              >
                No matching tickets found.
              </div>
              <Button
                style={{
                  textTransform: "none",
                  color: "var(--Interactive-Bg-Purple-5, #6200EA)",
                  fontFamily: "Rubik",
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                }}
                variant="text"
                onClick={clearAllSelectedFilters}
              >
                Clear filters
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupByTable;
