import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import {
  getSubTeamList,
  getTeamStatusList,
  returnWebSocketUrl,
} from "../../services/apiServices";
import {
  customAPICallWithPayload,
  customFetch,
} from "../../services/requestServices";
import { errorNotify } from "../../components/ToastComponent";
import { useParams } from "react-router-dom";
import useWebSocketService from "../../services/useWebSocketProvider";

const fieldMappings = {
  "L1 escalation notes": "l1_escalation_notes",
  "L2 escalation notes": "l2_escalation_notes",
  "Resolution notes": "resolution_notes",
};

const TicketContext = createContext();
export const useTicketDetail = () => useContext(TicketContext);

export const TicketProvider = ({ children }) => {
  const { team_id, ticket_id } = useParams();
  const [ticketData, setTicketData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [subTeams, setSubTeams] = useState([]);
  const [teamStatus, setTeamStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [focusType, setFocusType] = useState(null);
  const [incomingWSData, setIncomingWSData] = useState([]);

  const prevFetchTicketDetailsUrl = useRef(null);
  const prevFetchUrl = useRef(null);
  const prevFetchSubTeamUrl = useRef(null);
  const prevFetchTeamStatusUrl = useRef(null);

  const url = `${baseURL}v2/ticket/${ticket_id}`;
  const subTeamUrl = `${baseURL}v1/sub_team`;
  const teamStatusUrl = `${baseURL}v1/team`;

  const [activityUrl, setActivityUrl] = useState(
    `${baseURL}v1/task_audit/task_activity_logs/?ticket_id=${ticket_id}&limit=10`
  );

  // Websocket connection for activity data
  const ActivityDataWebSocketUrl = returnWebSocketUrl(baseURL, ticket_id);
  const ActivityDataWSResponse = useWebSocketService(ActivityDataWebSocketUrl);

  const fetchTicketData = async () => {
    if (url === prevFetchTicketDetailsUrl.current || !url) return;
    prevFetchTicketDetailsUrl.current = url;
    setIsLoading(true);
    const data = await customFetch(url, "GET", null);
    setTicketData(data);
    setIsLoading(false);
  };

  const fetchActivityData = async (customUrl = activityUrl) => {
    if (customUrl === prevFetchUrl.current || !customUrl) return;
    prevFetchUrl.current = customUrl;
    setIsLoadingLogs(true);

    const data = await customFetch(customUrl, "GET", null);
    setActivityData([...activityData, ...data.results]);

    if (data?.next != null) setActivityUrl(data.next);
    else setActivityUrl("");

    setIsLoadingLogs(false);
  };

  const fetchSubTeams = async (teamId) => {
    if (subTeamUrl === prevFetchSubTeamUrl.current || !subTeamUrl) return;
    prevFetchSubTeamUrl.current = subTeamUrl;

    const data = await getSubTeamList(teamId, subTeamUrl);
    setSubTeams(data);
  };

  const fetchTeamStatus = async (teamId) => {
    if (teamStatusUrl === prevFetchTeamStatusUrl.current || !teamStatusUrl)
      return;
    prevFetchTeamStatusUrl.current = teamStatusUrl;

    const data = await getTeamStatusList(teamId, teamStatusUrl);
    setTeamStatus(data);
  };

  const updatePriority = async (priority) => {
    const updateUrl = `${url}/update_priority/`;
    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      priority,
    });

    if (response === "success") {
      setTicketData((prev) => ({ ...prev, priority }));
    } else {
      errorNotify(`Failed to update priority. Please try again.`);
    }
  };

  const updateSubTeam = async (subTeam) => {
    const updateUrl = `${url}/update_subteam/`;
    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      sub_team_id: subTeam.id || "",
    });

    if (response === "success") {
      setTicketData((prev) => ({ ...prev, sub_team: subTeam.name }));
    } else {
      errorNotify(`Failed to update sub-team. Please try again.`);
    }
    return response;
  };

  const updateTicketDetails = async (field, updatedContent) => {
    const mappedField = fieldMappings[field] || field;
    const dataPayload = { [mappedField]: updatedContent };

    if (
      typeof dataPayload === "object" &&
      dataPayload !== null &&
      Object.keys(dataPayload).length > 0 &&
      !Object.entries(dataPayload).some(
        ([key, value]) =>
          key === "" || key === "[object Object]" || value == undefined
      )
    ) {

      const response = await customAPICallWithPayload(
        url,
        "PATCH",
        dataPayload
      );

      if (response === "success") {
        setTicketData((prev) => ({
          ...prev,
          ...(["L1 escalation notes", "L2 escalation notes"].includes(field)
            ? {
                escalation_notes: {
                  ...prev.escalation_notes,
                  [field]: updatedContent,
                },
              }
            : { [field]: updatedContent}),
        }));
      } else {
        errorNotify(`Failed to update status. Please try again.`);
      }

      return response;
    }
  };

  const valueObj = useMemo(
    () => ({
      ticketData,
      activityData,
      subTeams,
      teamStatus,
      isLoading,
      isLoadingLogs,
      showClear,
      setShowClear,
      focusType,
      setFocusType,
      fetchActivityData,
      fetchSubTeams,
      fetchTeamStatus,
      updatePriority,
      updateSubTeam,
      updateTicketDetails,
      incomingWSData,
    }),
    [
      ticketData,
      activityData,
      isLoading,
      isLoadingLogs,
      showClear,
      focusType,
      incomingWSData,
    ]
  );

  useEffect(() => {
    fetchTicketData();
    fetchActivityData();
  }, [ticket_id]);

  useEffect(() => {
    fetchSubTeams(team_id);
    fetchTeamStatus(team_id);
  }, [team_id]);

  useEffect(() => {
    if (ActivityDataWSResponse !== null) {
      try {
        const newData = ActivityDataWSResponse?.data;
        if (newData && typeof newData === "object") {
          setActivityData((prevActivityData) => [newData, ...prevActivityData]);
          setIncomingWSData(newData);
        } else {
          throw new Error("Invalid data structure received");
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error, {
          ActivityDataWSResponse,
          errorMessage: error.message,
        });
      }
    }
  }, [ActivityDataWSResponse]);

  return (
    <TicketContext.Provider value={valueObj}>{children}</TicketContext.Provider>
  );
};
