const createData = (id, label, numeric, disablePadding) => {
  return {
    id,
    label,
    numeric,
    disablePadding,
  };
};

export const statusHeadCells = [
  createData(1, "Name", false, false),
  createData(2, "ClickUp Link", false, false),
  createData(3, "Pending", true, false),
  createData(4, "L1 Pickup", true, false),
  createData(5, "L2 Pickup", true, false),
  createData(6, "L3 Pickup", true, false),
  createData(7, "In Progress", true, false),
  createData(16, "Reassign", true, false),
  createData(8, "Pending Dependency", true, false),
  createData(9, "Review", true, false),
  createData(10, "Status", false, false),
  createData(11, "SLA Status", false, false),
  createData(12, "Team", false, false),
  createData(13, "Sub Team", false, false),
  createData(14, "Created At", false, false),
  createData(15, "Completed At", false, false),
];

export const statusTableKeys = [
  "task_title",
  "clickup_task_id",
  "pending_status_duration",
  "l1_pickup_status_duration",
  "l2_pickup_status_duration",
  "l3_pickup_status_duration",
  "in_progress_status_duration",
  "reassign_status_duration",
  "pending_dependency_status_duration",
  "review_status_duration",
  "status",
  "sla_status",
  "team",
  "sub_team",
  "created_at",
  "completed_at",
];

export const levelHeadCells = [
  createData(1, "Name", false, false),
  createData(2, "ClickUp Link", false, false),
  createData(3, "L1", true, false),
  createData(4, "L2", true, false),
  createData(5, "L3", true, false),
  createData(9, "SLA Status", false, false),
  createData(10, "Team", false, false),
  createData(11, "Sub Team", false, false),
  createData(12, "Created At", false, false),
  createData(13, "Completed At", false, false),
];

export const levelTableKeys = [
  "task_title",
  "clickup_task_id",
  "l1_squad_duration",
  "l2_squad_duration",
  "l3_squad_duration",
  "sla_status",
  "team",
  "sub_team",
  "created_at",
  "completed_at",
];

export const squadHeadCells = [
  createData(1, "Name", false, false),
  createData(2, "ClickUp Link", false, false),
  createData(3, "Closed by squad", false, false),
  createData(4, "Closed by user", false, false),
  createData(9, "SLA Status", false, false),
  createData(10, "Team", false, false),
  createData(11, "Sub Team", false, false),
  createData(12, "Created At", false, false),
  createData(13, "Completed At", false, false),
];

export const squadTableKeys = [
  "task_title",
  "clickup_task_id",
  "closed_by_squad",
  "closed_by_user",
  "sla_status",
  "team",
  "sub_team",
  "created_at",
  "completed_at",
];

export const ticketHeadCells = [
  createData(1, "Title", false, false),
  createData(2, "Status", false, false),
  createData(3, "First Response SLA", false, false),
  createData(4, "SLA Status", false, false),
  createData(5, "Issue Category", false, false),
  createData(6, "Description", false, false),
  createData(7, "ClickUp Link", false, false),
  createData(8, "Assignee", false, false),
  createData(9, "Team", false, false),
  createData(10, "Sub Team", false, false),
  createData(11, "Priority", false, false),
  createData(12, "Created On", false, false),
  createData(13, "Due Date", false, false),
  createData(14, "Completed At", false, false),
];

export const ticketkeys = [
  "title",
  "status",
  "first_response_sla_achieved",
  "sla_status",
  "issue_category",
  "description",
  "clickup_link",
  "assignee",
  "team",
  "sub_team",
  "priority",
  "created_at",
  "due_date",
  "completed_at",
];

export const openStatus = [
  "L1 Pickup",
  "L2 Pickup",
  "L3 Pickup",
  "Pending Dependency",
  "Pending",
  "In progress",
];
