import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import NestedList from "../NestedList";

const RenderDynamicInput = ({
  inputType,
  inputName,
  ruleValues,
  handleInputChange,
  clickupProps,
  handleClickupList,
}) => {
  switch (inputType) {
    case "str":
      return (
        <TextField
          id="outlined-basic"
          string="string"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              maxHeight: "36px !important",
              fontSize: "14px",
              color: "#3c3e49",
            },
          }}
          type="text"
          placeholder={`Type ${inputName}`}
          value={ruleValues || ""}
          onChange={handleInputChange}
          fullWidth
        />
      );

    case "integer":
      return (
        <TextField
          id="outlined-basic"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              maxHeight: "36px !important",
              fontSize: "14px",
              color: "#3c3e49",
            },
          }}
          type="number"
          placeholder="Enter priority"
          value={ruleValues || ""}
          onChange={handleInputChange}
          fullWidth
        />
      );

    case "clickup":
      return (
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <TextField
            id="outlined-basic"
            variant="outlined"
            data-testid="outlined-basic"
            sx={{
              "& .MuiOutlinedInput-root": {
                maxHeight: "36px !important",
                fontSize: "14px",
                color: "#3c3e49",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
            }}
            value={ruleValues.list_name || ""}
            placeholder="Select ClickUp list"
          />
          <NestedList
            displayMode="dropdown"
            onSelectList={handleClickupList}
            listSelected={ruleValues}
            {...clickupProps}
          />
        </FormControl>
      );

    default:
      return (
        <TextField
          id="outlined-basic"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              maxHeight: "36px !important",
              fontSize: "14px",
              color: "#3c3e49",
            },
          }}
          type="text"
          placeholder="-"
          value={ruleValues?.list_name || ""}
          onChange={handleInputChange}
          fullWidth
        />
      );
  }
};

export default RenderDynamicInput;
