import { useState } from "react";
import { TicketProvider } from "../../context/TicketDetailContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import TicketInfo from "../../components/TicketInfo";
import TicketActivityLog from "../../components/TicketActivityLog";

const TicketsDetailsPage = () => {
  const [file, setFile] = useState("");
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <TicketProvider>
      <Box
        sx={{
          flexGrow: 1,
          overflow:"hidden"
        }}
      >
        <Grid container spacing={2}>
          <Grid size={8}>
            {" "}
            <TicketInfo file={file} handleChange={handleChange} />
          </Grid>
          <Grid size={4}>
            {" "}
            <TicketActivityLog />
          </Grid>
        </Grid>
      </Box>
    </TicketProvider>
  );
};

export default TicketsDetailsPage;
