import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { capitalizeFirstLetter } from "./clearAllSelectedFilters";
import Chip from "@mui/material/Chip";
import { arrowIcons, getColorStyles, getTrendText } from "./common_functions";

export const findCheckedStatusFromUrlParams = (tempArr, id, parentKey) => {
  return tempArr.includes(id);
};

export const findSuboptions = (subObjArr, parentKey, subOptionsFromParam) => {
  return subObjArr.map((sub) => {
    return {
      type: sub.name,
      colour: sub.color_code,
      id: sub.id,
      checked: subOptionsFromParam
        ? findCheckedStatusFromUrlParams(subOptionsFromParam, sub.id, parentKey)
        : false,
      parent: parentKey,
    };
  });
};

export const isParentFilterSelected = (item, urlParams) => {
  if (item in urlParams) {
    return urlParams[item].length > 0;
  } else return false;
};

const createFilterOption = (item, filterObj, combinedParams) => ({
  type: item,
  checked: isParentFilterSelected(item, combinedParams),
  isHighlighted: false,
  suboptions: findSuboptions(filterObj[item], item, combinedParams[item]),
  showsuboptions: false,
});

export const findFilterOptionsForDashboard = (
  filterObj,
  urlParams,
  urlParams2
) => {
  if (!filterObj) {
    const storedFilters = sessionStorage.getItem("allFilters");
    if (storedFilters) {
      filterObj = JSON.parse(storedFilters);
    }
  }
  const combinedParams = { ...urlParams, ...urlParams2 };
  return Object.keys(filterObj).map((item) =>
    createFilterOption(item, filterObj, combinedParams)
  );
};

export const findSlaData = (data) => {
  if (!data?.current_data?.sla_data) return [];

  const slaData = data.current_data.sla_data.map((item) => {
    const isAchieved = item.sla_status === "Achieved";
    const percentChangeData = isAchieved
      ? data.achieved_percent_change
      : data.breached_percent_change;

    return {
      name: item.sla_status,
      count: item.count,
      percent: item.percent,
      percent_change:
        percentChangeData[isAchieved ? "achieved_percent" : "breached_percent"],
      color: percentChangeData.color,
      arrow_type: percentChangeData.arrow_type,
      previous_data: data.previous_data.sla_data.find(
        (prev) => prev.sla_status === item.sla_status
      ) || {
        count: 0,
        percent: 0,
      },
    };
  });

  const hasAchieved = slaData.some((item) => item.name === "Achieved");
  const hasBreached = slaData.some((item) => item.name === "Breached");

  if (!hasAchieved) {
    slaData.push({
      name: "Achieved",
      count: 0,
      percent: 0,
      percent_change: data.achieved_percent_change.achieved_percent,
      color: data.achieved_percent_change.color,
      arrow_type: data.achieved_percent_change.arrow_type,
      previous_data: { count: 0, percent: 0 },
    });
  }

  if (!hasBreached) {
    slaData.push({
      name: "Breached",
      count: 0,
      percent: 0,
      percent_change: data.breached_percent_change.breached_percent,
      color: data.breached_percent_change.color,
      arrow_type: data.breached_percent_change.arrow_type,
      previous_data: { count: 0, percent: 0 },
    });
  }

  return slaData;
};

export const checkClosedBarDataKey = (
  closedBarData,
  legendKeysWithNonZeroData
) => {
  for (const closedBarDataKey in closedBarData) {
    if (closedBarDataKey != "name" && closedBarData[closedBarDataKey] > 0) {
      legendKeysWithNonZeroData.add(closedBarDataKey);
    }
  }
};

export const findLegendKeysWithNonZeroData = (formattedClosedBarData) => {
  const legendKeysWithNonZeroData = new Set();
  for (const closedBarData of formattedClosedBarData) {
    checkClosedBarDataKey(closedBarData, legendKeysWithNonZeroData);
  }

  return Array.from(legendKeysWithNonZeroData);
};

export const checkIfLegendKeyExists = (legendKey, nonZeroLegendKeys) => {
  let isLegendKeyExists = false;
  nonZeroLegendKeys.forEach((nonZerolegendKey) => {
    if (legendKey === nonZerolegendKey) isLegendKeyExists = true;
  });

  return isLegendKeyExists;
};

export const findLegendsToBeDisplayed = (legends, formattedClosedBarData) => {
  const nonZeroLegendKeys = findLegendKeysWithNonZeroData(
    formattedClosedBarData
  );

  const legendForNonZeroValues = [
    {
      ...legends[0],
      data: legends[0].data.filter((legendData) =>
        checkIfLegendKeyExists(legendData.id, nonZeroLegendKeys)
      ),
    },
  ];

  return legendForNonZeroValues;
};

export const convertToUpperCase = (str) => str.toUpperCase();
export const formatDuration = (duration) => duration * 1.1574e-5;
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const filterDataByDuration = (data) => {
  if (data != undefined) {
    const filterData = data?.current_data?.filter(
      (entry) => entry?.duration > 0
    );
    return filterData;
  }
};

export const formatStatusName = (str) => {
  let formattedString = str?.replace(/_/g, " ");
  formattedString = formattedString?.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );

  const words = formattedString?.split(" ");
  words?.pop();

  return words?.join("-");
};

export const formatStatusData = (data) => {
  if (data != undefined) {
    const formattedData = data.map((entry) => ({
      name: formatStatusName(entry.status),
      Pending: 0,
      "L1-Pickup": 0,
      "L2-Pickup": 0,
      "L3-Pickup": 0,
      "In-Progress": 0,
      Reassign: 0,
      "Pending-Dependency": 0,
      Review: 0,
    }));

    formattedData.forEach((entry) => {
      const apiEntry = data.find(
        (apiEntry) => formatStatusName(apiEntry.status) === entry.name
      );
      if (apiEntry) {
        const statusName = formatStatusName(apiEntry.status);
        entry[statusName] = formatDuration(apiEntry.adjustedValue);
        entry.actualValue = formatDuration(apiEntry.duration);
      }
    });

    return formattedData;
  }
};

export const formatSquadData = (data) => {
  if (data != undefined) {
    const formattedData = data.map((entry) => ({
      name: convertToUpperCase(entry.squad),
      L1: 0,
      L2: 0,
      L3: 0,
    }));

    formattedData.forEach((entry) => {
      const apiEntry = data.find(
        (apiEntry) => convertToUpperCase(apiEntry.squad) === entry.name
      );
      if (apiEntry) {
        const squadName = convertToUpperCase(apiEntry.squad);
        entry[squadName] = formatDuration(apiEntry.adjustedValue);
        entry.actualValue = formatDuration(apiEntry.duration);
      }
    });

    return formattedData;
  }
};

export const calculateMaxValue = (data) => {
  if (data !== undefined) {
    return Math.max(...data.map((item) => item.duration));
  }
};

export const adjustDataForVisualization = (data, maxValue) => {
  if (!Array.isArray(data) || data.length === 0 || maxValue == undefined) {
    return [];
  }

  return data.map((item) => {
    const adjustedValue =
      item.duration < maxValue / 20 ? maxValue / 20 : item.duration;

    return { ...item, adjustedValue };
  });
};

export const formatClosedData = (response) => {
  if (!response?.data) return [];

  return response.data.map((entry) => {
    const formattedDate = formatDate(entry.date.substring(0, 10));

    const formattedEntry = {
      name: formattedDate,
      l1: 0,
      l2: 0,
      l3: 0,
      auto: 0,
      internal: 0,
      external: 0,
    };

    entry.current_data.data.forEach((squad) => {
      const squadName = squad.closed_by_squad?.toLowerCase();
      if (squadName && formattedEntry.hasOwnProperty(squadName)) {
        formattedEntry[squadName] = squad.percent;
      }
    });

    return formattedEntry;
  });
};

export const isDataEmpty = (data, key) => {
  if (data == undefined || data.length === 0) return false;
  return data?.current_data?.every((entry) => entry[key] === 0);
};

export const isClosedDataEmpty = (response) => {
  if (response.data == undefined || response.data.length === 0) return false;

  return response.data.every((entry) => entry.current_data.data.length === 0);
};

export const calculateBarLabel = (item, maxValue) => {
  if ((item.value * maxValue) / 20 === 0.0) {
    return null;
  }
  const hours = item.data.actualValue * 24;
  if (hours >= 24) {
    const days = hours * 0.04166675;
    return `${days.toFixed(2)} d`;
  } else if (hours * 3600 <= 59) {
    const secs = hours * 3600;
    return `${secs.toFixed(2)} sec`;
  } else if (hours <= 1) {
    const mins = hours * 60;
    return `${mins.toFixed(2)} min`;
  } else {
    return `${hours.toFixed(2)} hr`;
  }
};

const convertToReadableFormat = (input, isSeconds = true) => {
  let seconds;

  if (isSeconds) {
    seconds = input;
  } else {
    seconds = input * 24 * 3600;
  }

  const hours = seconds / 3600;

  let unit, value;
  if (hours >= 24) {
    unit = "days";
    value = hours / 24;
  } else if (seconds <= 59) {
    unit = "sec";
    value = seconds;
  } else if (hours <= 1) {
    unit = "mins";
    value = seconds / 60;
  } else {
    unit = "hrs";
    value = hours;
  }

  return `${value.toFixed(2)} ${unit}`;
};

export const CustomItemTooltipContentStatus = (input, statusBarData) => {
  const readableDuration = convertToReadableFormat(
    input.data.actualValue,
    false
  );

  const statusName = input.data.name.toLowerCase();
  const formattedStatusName = statusName.replace("-", "_") + "_status";

  const changedData = statusBarData.changed_data || {};

  const trend =
    changedData[formattedStatusName]?.percentage_change ??
    changedData[statusName]?.percentage_change;

  const arrow_type =
    changedData[formattedStatusName]?.arrow_type ??
    changedData[statusName]?.arrow_type;

  const color =
    changedData[formattedStatusName]?.color ?? changedData[statusName]?.color;

  const { icon, background, border } = getColorStyles(color);

  return (
    <Paper sx={{ padding: 2, backgroundColor: "#3C3E49", color: "#ffffff" }}>
      <p>Status: {input.data.name}</p>
      <p>Average Time spent: {readableDuration}</p>
      <div>
        Trends:{" "}
        <Chip
          label={`${Math.abs(trend)}%`}
          icon={arrowIcons(arrow_type, icon)}
          sx={{
            marginLeft: "0.5rem",
            height: "1.2rem",
            backgroundColor: background,
            color: icon,
            border: border,
            fontFamily: "Rubik",
            fontWeight: 500,
          }}
        />
      </div>
      <p>({getTrendText(trend)})</p>
    </Paper>
  );
};

export const CustomItemTooltipContentTeam = (input, closedBarData) => {
  const { index: item, id: squadName } = input;
  const dataEntry = closedBarData.data[item];

  if (!dataEntry)
    return {
      count: 0,
      percentage: 0,
      trendPercent: null,
      arrow_type: null,
      styles: {},
    };

  const squadData = dataEntry.current_data.data?.find(
    (squad) => squad?.closed_by_squad?.toLowerCase() === squadName
  );

  const count = squadData?.count ?? 0;
  const percentage = squadData?.percent ?? 0;

  return (
    <Paper sx={{ padding: 2, backgroundColor: "#3C3E49", color: "#ffffff" }}>
      <p> Status: Tickets closed by {capitalizeFirstLetter(squadName)} </p>
      <p>Day: {input.data.name}</p>
      <p>
        Tickets: {count} tickets ({percentage}%)
      </p>
    </Paper>
  );
};

export const getLoader = () => {
  return <Skeleton variant="rounded" width={"100%"} height={"20vh"} />;
};

export const getStatusRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    pending_status_duration: convertToReadableFormat(
      data.pending_status_duration,
      true
    ),
    l1_pickup_status_duration: convertToReadableFormat(
      data.l1_pickup_status_duration,
      true
    ),
    l2_pickup_status_duration: convertToReadableFormat(
      data.l2_pickup_status_duration,
      true
    ),
    l3_pickup_status_duration: convertToReadableFormat(
      data.l3_pickup_status_duration,
      true
    ),
    in_progress_status_duration: convertToReadableFormat(
      data.in_progress_status_duration,
      true
    ),
    reassign_status_duration: convertToReadableFormat(
      data.reassign_status_duration,
      true
    ),
    pending_dependency_status_duration: convertToReadableFormat(
      data.pending_dependency_status_duration,
      true
    ),
    review_status_duration: convertToReadableFormat(
      data.review_status_duration,
      true
    ),
    status: data.status,
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team,
    created_at: data.created_at,
    completed_at: data.completed_at,
  }));

export const getLevelRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    l1_squad_duration: convertToReadableFormat(data.l1_squad_duration, true),
    l2_squad_duration: convertToReadableFormat(data.l2_squad_duration, true),
    l3_squad_duration: convertToReadableFormat(data.l3_squad_duration, true),
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team,
    created_at: data.created_at,
    completed_at: data.completed_at,
  }));

export const getSquadRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    closed_by_squad: data.closed_by_squad,
    closed_by_user: data.closed_by_user,
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team,
    created_at: data.created_at,
    completed_at: data.completed_at,
  }));
