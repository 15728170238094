import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import RocketIcon from "@mui/icons-material/Rocket";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ListIcon from "@mui/icons-material/List";
import CheckIcon from "@mui/icons-material/Check";
import Skeleton from "@mui/material/Skeleton";
import "./style.css";

const isSelected = (item, listSelected, dataType) => {
  const selectedList = [].concat(listSelected);

  if (dataType === "spaces") {
    return selectedList.some((selected) => selected?.space_id === item?.id);
  }
  if (dataType === "folders") {
    return selectedList.some((selected) => selected?.folder_id === item?.id);
  }

  return false;
};

export const DynamicList = ({
  renderItem,
  sx,
  items,
  fetchItems,
  handleItemClick,
  openItems,
  dataType,
  listSelected,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    await fetchItems();
    setIsLoading(false);
  };

  const getIcon = (type) => {
    switch (type) {
      case "spaces":
        return <RocketIcon />;
      case "folders":
        return <FolderOpenIcon />;
      case "list":
        return <ListIcon />;
      default:
        return <ArrowForwardIosSharpIcon />;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className="loader">
      <Skeleton
        role="progress"
        animation="wave"
        sx={{
          height: "3rem",
        }}
      />
    </div>
  ) : (
    <List component="div" disablePadding sx={sx}>
      {items.map((item) =>
        dataType == "lists_without_folder" || dataType == "lists" ? (
          renderItem(item)
        ) : (
          <div key={item.id}>
            <ListItemButton
              onClick={() => handleItemClick(item.id)}
              sx={{
                py: "0.1rem",
                marginBottom: "0.2rem",
                borderRadius: "6px",
                backgroundColor: isSelected(item, listSelected, dataType)
                  ? "#e5e4fc"
                  : "inherit",
              }}
            >
              {openItems[item.id] ? (
                <ArrowForwardIosSharpIcon fontSize="0.9rem" />
              ) : (
                getIcon(dataType)
              )}
              <ListItemText primary={item.name} sx={{ marginLeft: "0.5rem" }} />
            </ListItemButton>
            <Collapse
              in={openItems[item.id]}
              key={item.id}
              timeout="auto"
              unmountOnExit
            >
              {renderItem(item)}
            </Collapse>
          </div>
        )
      )}
    </List>
  );
};

export const RenderList = ({ onClick, selectedList, list, listSelected }) => {
  const isSelected =
    selectedList == list?.id || listSelected?.list_id == list?.id;

  return (
    <List component="div" disablePadding key={list.id}>
      <ListItemButton
        onClick={onClick}
        sx={{
          paddingBottom: 0,
          paddingTop: 0,
          borderRadius: "6px",
          marginBottom: "0.2rem",
          backgroundColor: isSelected ? "#e5e4fc" : "inherit",
          "&:hover": {
            backgroundColor: isSelected ? "#e5e4fc" : "#f0f0f0",
          },
        }}
      >
        <ListIcon />
        <ListItemText primary={list.name} />
        {isSelected && (
          <CheckIcon
            sx={{
              color: "rgb(101, 31, 255)",
              marginLeft: "auto",
            }}
          />
        )}
      </ListItemButton>
    </List>
  );
};
